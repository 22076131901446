import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./knowledge.css";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Select from 'react-select';
import { Editor } from '@tinymce/tinymce-react';
import { apiService } from '../../services/apiService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function DraftEdit() {
    const location = useLocation();
    const currentPath = location.pathname;
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const [authorDetail,setAuthorDetail] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [catesNames,setCateNames] = useState('');
    const [taskType, setTaskType] = useState([]);
    const [baseids, setBaseid] = useState([]);
    const [categoryId, setCategoryid] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subcategoryId, setSubCategoryid] = useState([]);
    const [articleDetail, setArticleDetail] = useState([]);
    const [selectedBaseid, setSelectedBaseid] = useState(null);
    const [selectedCatId, setSelectedCategoryid] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedsubCatId, setSelectedsubCategoryid] = useState(null);
    const tomorrow = dayjs().add(1, 'day');
    const [submitloader, setSubmitloader] = useState("");
    const [previewer, setPreview] = useState("false");
    const [value, setValue] = useState(dayjs(null));
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [tags, setTags] = useState([]);
    const author_ids = Number(localStorage.getItem('userid'));
    const [backActive, setBackActive] = useState(true);
    const isAuthor = (authorDetail && authorDetail.id === author_ids && articleDetail.status === "draft");
    const [input, setInput] = useState("");
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    
    const fetchSuggestions = async (query) => {
        if (!query) {
            setSuggestions([]); // Clear suggestions if input is empty
            return;
        }
    
        try {
            const response = await apiService.postRequest("knowledge-article/tag-suggestions", { query });
            if(response && response.success){
                setSuggestions(response.tags || []); // Adjust based on API response
            }
        } catch (error) {
            console.error("Error fetching suggestions:", error);
        }
    };
    const handleInputChanger = (e) => {
        setInput(e.target.value);
        fetchSuggestions(e.target.value);
        if (errors.tags) {
            setErrors(prevErrors => ({ ...prevErrors, tags: undefined }));
        }
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && input.trim()) {
            e.preventDefault();
            const newTag = input.trim();
            if (!tags.includes(newTag)) {
                const updatedTags = [...tags, newTag];
                setTags(updatedTags);
    
                // Update formData's tags to a comma-separated string
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    tags: updatedTags.join(", "),
                }));
            }
            setInput("");
        }
    };
    const handleSuggestionClick = (suggestion) => {
        if (!tags.includes(suggestion)) {
          setTags([...tags, suggestion]);
        }
        setInput(""); // Clear input
        setSuggestions([]); // Hide suggestions
    };
    const removeTag = (index) => {
        const updatedTags = tags.filter((tag, i) => i !== index);
        setTags(updatedTags);
    
        // Update the formData tag property after removing the tag
        setFormData((prevFormData) => ({
            ...prevFormData,
            tags: updatedTags.join(", "), // Update to a comma-separated string
        }));
    };
    
    const [formData, setFormData] = useState({
        title: '',
        retired_date: '',
        status: '',
        task_type: '',
        tags:'',
        base_id: '',
        category_id: '',
        subcategory_id: '',
        content: '',
    });
    const [settersData, setsettersData] = useState({
        title: '',
        retired_date: '',
        status: '',
        task_type: '',
        tags:'',
        base_id: '',
        category_id: '',
        subcategory_id: '',
        content: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Clear the error for the specific field being modified
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (newValue) => {
        if (newValue) {
            setValue(newValue);
            setFormData({ ...formData, retired_date: newValue.format('YYYY-MM-DD') });

            // Clear the error for retired_date if a valid date is entered
            if (errors.retired_date) {
                setErrors({ ...errors, retired_date: '' });
            }
        } else {
            setValue(null);
            setFormData({ ...formData, retired_date: '' }); // Clear the value if null
        }
    };

    const handleEditorChange = (htmlContent) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: htmlContent,
        }));
        
        // Clear content error if the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            content: '',
        }));
    };

    const { id } = useParams();

    useEffect(() => {
        const fetchPreviewData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`knowledge-article/${id}`);
                if (response && response.success) {
                    setArticleDetail(response.data);
                    setAuthorDetail(response.data.author);
                    setCateNames(response.data.subcategory);
                    const existingTags = response.data.tags ? response.data.tags.split(',').map(tag => tag.trim()) : [];
                    setTags(existingTags);
                    setFormData({
                        title: response.data.title,
                        retired_date: response.data.retired_date,
                        status: response.data.status,
                        base_id: response.data.base.id,
                        category_id: response.data.category.id,
                        subcategory_id: response.data.subcategory.id,
                        content: response.data.content,
                        task_type: response.data.task_type || [],
                    });
                    setsettersData({
                        title: response.data.title,
                        retired_date: response.data.retired_date,
                        status: response.data.status,
                        base_id: response.data.base.id,
                        category_id: response.data.category.id,
                        subcategory_id: response.data.subcategory.id,
                        content: response.data.content,
                        task_type: response.data.task_type || [],
                    });
                    setValue(dayjs(response.data.retired_date));
                    setSelectedBaseid({
                        label: response.data.base.name,
                        value: response.data.base.id,
                    });
                    setSelectedCategoryid({
                        label: response.data.category.name,
                        value: response.data.category.id,
                    });
                    setSelectedsubCategoryid({
                        label: response.data.subcategory.name,
                        value: response.data.subcategory.id,
                    });
                    const selectedTasks = response.data.task_type.map(task => ({
                        label: task,
                        value: task,
                    }));
                    setSelectedTask(selectedTasks);
                }
            } catch (err) {
                console.error(err);
            }finally{
                setLoading(false);
            }
        };

        fetchPreviewData();
    }, [id]);

    useEffect(() => {
        const isFormMatchingSetters = Object.keys(formData).every((key) => {
            const formValue = formData[key];
            const setterValue = settersData[key];
    
            if (Array.isArray(formValue) && Array.isArray(setterValue)) {
                // Compare arrays
                return formValue.length === setterValue.length &&
                    formValue.every((item, index) => item === setterValue[index]);
            } else if (typeof formValue === 'object' && typeof setterValue === 'object') {
                // Compare objects (deep comparison)
                return JSON.stringify(formValue) === JSON.stringify(setterValue);
            } else {
                // Compare primitives
                return formValue === setterValue;
            }
        });
        setBackActive(isFormMatchingSetters);
    }, [formData, settersData]);
    const handleTaskChange = (selectedOptions) => {
        setSelectedTask(selectedOptions); // Update the selected options for display
    
        // Extract values from the selected options array
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    
        // Update formData with the selected values (as an array)
        setFormData((prevFormData) => ({
            ...prevFormData,
            task_type: selectedValues,
        }));
    };
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.getRequest('knowledge-bases-with-task-types');
            if (response.success) {
                setBaseid(response.data);
                setTaskType(response.taskTypes);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (formData.base_id) {
            const fetchData = async () => {
                const response = await apiService.getRequest(`knowledge-article-categories-list/${formData.base_id}`);
                if (response.success) {
                    setCategoryid(response.data || []);
                    // setSelectedCategoryid(null);
                }
            };
            fetchData();
        }
    }, [formData.base_id]);

    useEffect(() => {
        if (formData.category_id) {
            const fetchData = async () => {
                const response = await apiService.getRequest(`knowledge-article-subcategories-list/${formData.category_id}`);
                if (response.success) {
                    setSubCategoryid(response.data || []);
                    // setSelectedsubCategoryid(null);
                }
            };
            fetchData();
        }
    }, [formData.category_id]);

    const handlebaseidChange = (selectedOption) => {
        setSelectedBaseid(selectedOption);
        handleInputChange({
            target: {
                name: 'base_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
        // Clear the category and subcategory when base is changed
        setSelectedCategoryid(null);
        setSelectedsubCategoryid(null);
    };

    const handleCatidChange = (selectedOption) => {
        setSelectedCategoryid(selectedOption);
        handleInputChange({
            target: {
                name: 'category_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const handleSubCatidChange = (selectedOption) => {
        setSelectedsubCategoryid(selectedOption);
        handleInputChange({
            target: {
                name: 'subcategory_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const validateForm = () => {
        let valid = true;
        const errors = {};

        if (!formData.title || formData.title.trim() === '') {
            errors.title = 'Title is required.';
            valid = false;
        }

        if (!formData.retired_date || formData.retired_date.trim() === '') {
            errors.retired_date = 'Retired date is required.';
            valid = false;
        } else if (!dayjs(formData.retired_date, 'YYYY-MM-DD', true).isValid()) {
            errors.retired_date = 'Invalid date format. Use YYYY-MM-DD.';
            valid = false;
        }

        if (!Array.isArray(formData.task_type) || formData.task_type.length === 0) {
            errors.task_type = 'At least one task type is required.';
            valid = false;
        }

        if (!formData.base_id) {
            errors.base_id = 'Base ID is required.';
            valid = false;
        }

        if (!formData.category_id) {
            errors.category_id = 'Category ID is required.';
            valid = false;
        }

        if (!formData.subcategory_id) {
            errors.subcategory_id = 'Subcategory ID is required.';
            valid = false;
        }

        if (!formData.content || formData.content.trim() === '') {
            errors.content = 'Content is required.';
            valid = false;
        }

        if (!tags.length) {
            errors.tags = 'At least one tag is required.';
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    const handleSubmit = async (e, status, previewer,reviser) => {
        e.preventDefault();
        if (!validateForm()) {
            setErrorMessage('Please fix the errors before proceeding.');
            return;
        }
    
        setSubmitloader(status);
        setPreview(previewer);
        try {
            const jsonParams = {
                ...formData,
                status,
                tags: tags.join(", "), // Ensure that the tags are correctly included in the form data
                ...(reviser === "true" && { revise: true })
            };
    
            const response = await apiService.patchRequest(`knowledge-article/${id}`, jsonParams);
            if (response && response.success) {
                setErrors({});
                const isPreviewer = previewer === "true";

                if(isPreviewer){
                    navigate(`/knowledge/preview/${id}`)
                    toast('Article Saved to Draft successfully!');
                }else if( status ==="draft" ){
                    toast('Article Saved to Draft successfully!');
                }else if( status ==="under_approval" ){
                    navigate('/knowledge/under-approval');
                    toast('Article Moved to Approval successfully!');
                }else if( status ==="published" ){
                    navigate('/knowledge/published');
                    toast('Article Moved to Publish successfully!');
                }else if( status ==="retired" ){
                    navigate('/knowledge/retired');
                    toast('Article Moved to Retire successfully!');
                }
            } else {
                setErrorMessage(response.message || 'An error occurred during submission.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorMessage('Failed to submit the form. Please try again.');
        } finally {
            setSubmitloader("");
        }
    };

    const [show, setShow] = useState(false);
    const [selectedArticleId, setSelectedArticleId] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (articleId) => {
        setSelectedArticleId(articleId);
        setShow(true);
    };

    const handleDelete = async () => {
        try {
            const response = await apiService.deleteRequest(`knowledge-article/${selectedArticleId}`);
            if(response && response.success === true){
                toast('Article deleted successfully!');
                if(articleDetail && articleDetail.status === "draft"){
                    navigate('/knowledge/draft');
                }
                else{
                    navigate('/knowledge/retired');
                }
            }else{
                toast.error("Failed to delete Article");
            }
        } catch (err) {
            console.error("Failed to delete Article");
        }
        handleClose();
    };
    const styles = {
        tagContainer: {
          display: "flex",
          flexWrap: "wrap",
          padding: "0px",
          border: "none",
          borderRadius: "5px",
        },
        tag: {
          display: "flex",
          alignItems: "center",
          backgroundColor: "#B8D7FF",
          borderRadius: "3px",
          padding: "5px",
          marginRight: "5px",
          marginBottom: "5px",
          fontWeight:"600",
        },
        removeButton: {
          marginLeft: "5px",
          border: "none",
          background: "transparent",
          cursor: "pointer",
          fontSize: "16px",
        },
      };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center" className="mario_fixers_article">
                    {articleDetail && articleDetail.status === "under_approval" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Under Approval</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                    {articleDetail && articleDetail.status === "draft" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Edit Draft</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                    {articleDetail && articleDetail.status === "published" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Published</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                    {articleDetail && articleDetail.status === "retired" ? (
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Retired</h3>
                        <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                    </Grid>
                    ):""}
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100 mt-5'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                    <div className='knowledgetab_btnredirect'>
                        <button className={currentPath.startsWith("/knowledge/edit-article") ? "active_tabbtn" : ""} onClick={() =>{navigate(`/knowledge/edit-article/${id}`)}}>Article</button>
                        {articleDetail && articleDetail.status === "draft" ? "":
                        <button onClick={() =>{navigate(`/knowledge/under-approval/show/${id}`)}}>Approvals</button>
                        }
                        <button onClick={() =>{navigate(`/knowledge/under-approval/history/${id}`)}}>History</button>
                        <button onClick={() =>{navigate(`/knowledge/attachments/${id}`)}}>Attachments</button>
                        <button onClick={() =>{navigate(`/knowledge/discussions/${id}`)}}>Discussions</button>
                        <button onClick={() =>{navigate(`/knowledge/versions/${id}`)}}>Versions</button>
                    </div>
                </Grid>
                <Grid size={{xs:12}} container justifyContent="space-between">
                <form className='w-100' 
                    onSubmit={handleSubmit}
                    >
                    <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="darfts_inputs">
                        {articleDetail && articleDetail.status === "under_approval" ? (
                            <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="gap-md-4 gap-2">
                                <button className='registersendbtn1' type='button' onClick={() => navigate(`/knowledge/preview/${articleDetail.id}`)}>Preview</button>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "draft" ? (
                            <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="gap-md-4 gap-2">
                                <button style={{width:"max-content"}} className='registersendbtn2 px-4' type="submit" onClick={(e) => handleSubmit(e,"under_approval")}>{submitloader === "under_approval" ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Request for Approval'}</button>
                                <button className='registersendbtn1' type="submit" onClick={(e) => handleSubmit(e,"draft", "false")}>{(submitloader === "draft" && previewer === "false") ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Save'}</button>
                                <button type='button' className='registersendbtn1 px-4' onClick={() => handleShow(id)}>Delete</button>
                                <button
                                    className="registersendbtn1"
                                    type='button'
                                    onClick={() => {
                                        if(backActive){
                                            navigate("/knowledge/draft");
                                        }else{
                                            setShow2(true);
                                        }
                                    }}
                                    >
                                    Back
                                </button>
                                <button className='registersendbtn1' type="submit" onClick={(e) => {handleSubmit(e,(formData.status), "true")}}>{(previewer === "true") ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Preview'}</button>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "published" ? (
                            <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="gap-md-4 gap-2">
                                <button className='registersendbtn1' onClick={() =>{navigate("/knowledge/published")}}>Back</button>
                                <button className='registersendbtn1' type="submit" onClick={(e) => handleSubmit(e,"retired", "false")}>{(submitloader === "retired") ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Retire'}</button>
                                <button className='registersendbtn2 px-4' type="submit" onClick={(e) => handleSubmit(e,"draft","false","true")}>{submitloader === "draft" ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Revise'}</button>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "retired" ? (
                        <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="gap-md-4 gap-2">
                            <button className='registersendbtn1' onClick={() =>{navigate("/knowledge/retired")}}>Back</button>
                            <button type='button' className='registersendbtn2 px-4' onClick={() => handleShow(id)}>Delete</button>
                        </Grid>
                        ):""}
                        <Grid size={{xs:12}} container justifyContent="space-between" className="author_spans">
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column" className="author_spans">
                                <span>Author</span>
                                <h5>{authorDetail.name}</h5>
                            </Grid>
                            <Grid size={{sm:5.8, xs:12}} container flexDirection="column" className="author_spans">
                                <span>Number</span>
                                <h5>{articleDetail.format_id}</h5>
                            </Grid>
                        </Grid>
                        <Grid size={{sm:12, xs:12}} container flexDirection="column">
                            <span>Title<small>*</small></span>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                disabled={!isAuthor}
                                // onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                            />
                            {errors.title && <p style={{ color: "Red" }}>{errors.title}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Retire Date<small>*</small></span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="date_pickermux"
                                        value={value}
                                        onChange={handleDateChange}
                                        minDate={tomorrow}
                                        disabled={!isAuthor}
                                        renderInput={(params) => <input {...params}/>}
                                    />
                                </LocalizationProvider>
                                {errors.retired_date && <p style={{ color: "Red" }}>{errors.retired_date}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Version<small>*</small></span>
                            <input type='text' value={articleDetail.current_version} disabled/>
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Knowledge Base<small>*</small></span>
                            <Select
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                value={selectedBaseid}
                                defaultValue={null}
                                isDisabled={!isAuthor}
                                name="base_id"
                                options={baseids && baseids.map((state) => ({
                                    label: state.name,
                                    value: state.id,
                                }))}
                                onChange={handlebaseidChange}
                            />
                            {errors.base_id && <p style={{color:"Red"}}>{errors.base_id}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Select all applicable task types<small>*</small></span>
                            <Select
                            isMulti
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                value={selectedTask}
                                defaultValue={null}
                                name="task_type"
                                isDisabled={!isAuthor}
                                options={taskType && taskType.map((task) => ({
                                    label: task.name,
                                    value: task.name,
                                }))}
                                onChange={handleTaskChange}
                            />
                            {errors.task_type && <p style={{color:"Red"}}>{errors.task_type}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Category<small>*</small></span>
                            <Select
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                value={selectedCatId}
                                defaultValue={null}
                                isDisabled={!isAuthor}
                                name="category_id"
                                options={categoryId.map((cates) => ({
                                    label: cates.name,
                                    value: cates.id,
                                }))}
                                onChange={handleCatidChange}
                            />
                            {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Sub Category<small>*</small></span>
                            <Select
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                value={selectedsubCatId}
                                defaultValue={null}
                                isDisabled={!isAuthor}
                                name="subcategory_id"
                                options={subcategoryId.map((subcates) => ({
                                    label: subcates.name,
                                    value: subcates.id,
                                }))}
                                onChange={handleSubCatidChange}
                            />
                            {errors.subcategory_id && <p style={{color:"Red"}}>{errors.subcategory_id}</p>}
                        </Grid>
                        <Grid size={{xs:12}} container className="mt-5">
                        <Editor
                            disabled={!isAuthor}
                            apiKey='spl4mbm1ksgtbx9txgs350v68n29xmoqkagakiens2wif5jb'
                            value={formData.content}
                            onEditorChange={handleEditorChange}
                            onInit={(_evt, editor) => editorRef.current = editor}
                            initialValue="<p>This is the initial content of the editor.</p>"
                            init={{
                                height: 500,
                                menubar: 'file edit view insert format tools table',

                                // Custom menu configuration to include all options
                                menu: {
                                file: { title: 'File', items: 'newdocument restoredraft | preview | print | deleteallconversations' },
                                edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                                view: { title: 'View', items: 'code | visualaid visualblocks visualchars | preview fullscreen' },
                                insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap hr pagebreak nonbreaking anchor toc' },
                                format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' },
                                tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                                table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' }
                                },

                                plugins: [
                                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                'bold italic forecolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'image inserttable table | removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                tinymceScriptSrc: "https://cdn.jsdelivr.net/npm/tinymce@6.0.3/tinymce.min.js"
                            }}
                        />
                            {errors.content && <p style={{color:"Red"}}>{errors.content}</p>}
                        </Grid> 
                        <Grid size={{sm:12, xs:12}} container flexDirection="column" style={{position:"relative"}}>
                        <span>Tag<small>*</small></span>
                        <input
                            type="text"
                            disabled={!isAuthor}
                            value={input}
                            onChange={handleInputChanger}
                            onKeyDown={handleKeyDown}
                            placeholder="Type and press Enter"
                            style={styles.input}
                        />
                        {suggestions.length > 0 && (
                            <ul style={{ 
                                listStyle: "none", 
                                padding: "5px", 
                                border: "1px solid #ccc", 
                                borderRadius: "4px", 
                                margin: 0, 
                                position: "absolute", 
                                top:"100%",
                                background: "#fff", 
                                width: "250px",
                                height:"150px",
                                overflow:"auto",
                                zIndex: 10, 
                            }}>
                                {suggestions.map((tag, index) => (
                                    <li 
                                        key={index} 
                                        style={{ padding: "5px", cursor: "pointer" }} 
                                        onClick={() => handleSuggestionClick(tag)}
                                    >
                                        {tag}
                                    </li>
                                    ))}
                                </ul>
                            )}
                        <div style={styles.tagContainer}>
                            {tags && tags.map((tag, index) => (
                            <div key={index} style={styles.tag}>
                                {tag}
                                <button
                                type="button"
                                style={styles.removeButton}
                                onClick={() => removeTag(index)}
                                disabled={!isAuthor}
                                >
                                &times;
                                </button>
                            </div>
                            ))}
                        </div>
                        {errors.tags && <p style={{color:"Red"}}>{errors.tags}</p>}
                        </Grid>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        
                        {articleDetail && articleDetail.status === "under_approval" ? (
                            <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="gap-md-4 gap-2">
                                <button className='registersendbtn1' type='button' onClick={() => navigate(`/knowledge/preview/${articleDetail.id}`)}>Preview</button>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "draft" ? (
                            <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="gap-md-4 gap-2">
                                <button style={{width:"max-content"}} className='registersendbtn2 px-4' type="submit" onClick={(e) => handleSubmit(e,"under_approval")}>{submitloader === "under_approval" ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Request for Approval'}</button>
                                <button className='registersendbtn1' type="submit" onClick={(e) => handleSubmit(e,"draft", "false")}>{(submitloader === "draft" && previewer === "false") ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Save'}</button>
                                <button type='button' className='registersendbtn1 px-4' onClick={() => handleShow(id)}>Delete</button>
                                {/* <button className='registersendbtn1' onClick={() =>{navigate("/knowledge/draft")}}>Back</button> */}
                                <button
                                    className="registersendbtn1"
                                    type='button'
                                    onClick={() => {
                                        if(backActive){
                                            navigate("/knowledge/draft");
                                        }else{
                                            setShow2(true);
                                        }
                                    }}
                                    >
                                    Back
                                    </button>
                                <button className='registersendbtn1' type="submit" onClick={(e) => {handleSubmit(e,(formData.status), "true")}}>{(previewer === "true") ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Preview'}</button>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "published" ? (
                            <Grid size={{sm:12, xs:12}} container justifyContent="flex-start" className="gap-md-4 gap-2">
                                <button className='registersendbtn1' onClick={() =>{navigate("/knowledge/published")}}>Back</button>
                                <button className='registersendbtn1' type="submit" onClick={(e) => handleSubmit(e,"retired", "false")}>{(submitloader === "retired") ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Retire'}</button>
                                <button className='registersendbtn2 px-4' type="submit" onClick={(e) => handleSubmit(e,"draft","false","true")}>{submitloader === "draft" ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Revise'}</button>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "retired" ? (
                        <Grid size={{sm:12, xs:12}} container justifyContent="flex-start" className="gap-md-4 gap-2">
                            <button className='registersendbtn1' onClick={() =>{navigate("/knowledge/retired")}}>Back</button>
                            <button type='button' className='registersendbtn2 px-4' onClick={() => handleShow(id)}>Delete</button>
                        </Grid>
                        ):""}
                    </Grid>
                </form>
                </Grid>
                </>}
            </Grid>
            <Modal
                show={show2} 
                onHide={handleClose2}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{border:"none"}}>
                    <h4 className='mb-0'>Leave Page</h4>
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex w-100 flex-column modal_register">
                    <h5>Do you want to save changes before leaving this page?</h5>
                    <p>Your changes will be lost if you leave now.</p>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <button className='blue_primarymodalbtn1' onClick={handleClose2}>
                    Cancel
                </button>
                <button className='blue_primarymodalbtn2' onClick={() => navigate("/knowledge/draft")}>
                    Leave
                </button>
                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Article?</Modal.Body>
                <Modal.Footer>
                    <button className='modal_cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='modal_delete' onClick={handleDelete}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default DraftEdit
