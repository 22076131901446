import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { faAngleRight, faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import "./knowledge.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { apiService } from '../../services/apiService';
import { Modal } from 'react-bootstrap';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';
function KnowledgeCategory() {
    const navigate = useNavigate();

    const [categorys, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [savesbtnloading, setSavesbtnloading] = useState(false);
    const [selectList, setSelectlist] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedCategoryId, setCategoryId] = useState(null);
    const handleClose = () => setShow(false);


    const fetchRoots = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const queryString = new URLSearchParams(params).toString();
            const response = await apiService.getRequest(`/knowledge-article-categories?${queryString}`);
            if (response && response.success === true) {
                setCategory(response.data);
            }
        } catch (error) {
            console.error('Error fetching tab data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.getRequest('knowledge-bases');
            if (response.success) {
                setSelectlist(response.data);
            }
        };
        fetchData();
    }, []);

    const handleSearch = () => {
        fetchRoots({ search: searchQuery });
        setCurrentPage(0);
    };

    useEffect(() => {
        fetchRoots();
    }, [fetchRoots]);

    const handleInputChange = (e) => {
        setName(e.target.value);
        if (nameError) {
            setNameError(''); // Clear the name error when the user starts typing
        }
    };

    // Handle select change for category
    const handleSelectChange = (selectedOption) => {
        setSelectedCategory(selectedOption);
        if (categoryError) {
            setCategoryError(''); // Clear the category error when the user selects an option
        }
    };
    // Validation for individual fields
    const validateFields = () => {
        let valid = true;

        // Validate name
        if (name.trim() === '') {
            setNameError('Please enter a category name.');
            valid = false;
        } else {
            setNameError(''); // Clear the name error if valid
        }

        // Validate selected category
        if (!selectedCategory) {
            setCategoryError('Please select a Knowledge Base.');
            valid = false;
        } else {
            setCategoryError(''); // Clear the category error if valid
        }

        return valid;
    };
    const handleSubmit = async () => {

        if (!validateFields()) return;

        try {
            setSavesbtnloading(true);
            if (editMode && editId) {
                const response = await apiService.patchRequest(`/knowledge-article-categories/${editId}`, {
                    name: name,
                    knowledge_base_id: selectedCategory.value, // Assuming category ID is required
                });
                if (response && response.success) {
                    setEditMode(false);
                    setEditId(null);
                    setName('');
                    setSelectedCategory(null); // Reset category select
                    fetchRoots();
                }else{
                    setNameError(response && response.message)
                }
            } else {
                const response = await apiService.postRequest('/knowledge-article-categories', {
                    name: name,
                    knowledge_base_id: selectedCategory.value, // Sending selected category ID
                });
                if (response && response.success) {
                    setName('');
                    setSelectedCategory(null); // Reset category select
                    fetchRoots();
                }else{
                    setNameError(response && response.message)
                }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setSavesbtnloading(false);
        }
    };
    const handleCancel = () => {
        setName('');
        setSelectedCategory(null);
        setEditMode(false);
        setEditId(null);
    };
    const handleEdit = async (id) => {
        try {
            const response = await apiService.getRequest(`/knowledge-article-categories/${id}`);
            if (response && response.success) {
                setName(response.data.name);
                const selectedOption = selectList.find(option => option.id === response.data.knowledge_base_id);
                setSelectedCategory({
                    value: selectedOption?.id,
                    label: selectedOption?.name
                });
                setEditMode(true);
                setEditId(id);
            }
        } catch (error) {
            console.error('Error fetching article:', error);
        }
    };

    const handleShow = (rootId) => {
        setCategoryId(rootId);
        setShow(true);
    };

    const handleDelete = async () => {
        try {
            await apiService.deleteRequest(`/knowledge-article-categories/${selectedCategoryId}`); // Call DELETE API
            setCategory(categorys.filter(categorys => categorys.id !== selectedCategoryId));
        } catch (err) {
            toast.error("Failed to delete Categories");
        }
        handleClose();
    };
    const rows = categorys.map((datas,index) => ({
        index:index+1,
        id: datas.id,
        name: datas.name,
        knowledgebase: datas.knowledge_base.name,
        
    }));
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const paginatedRows = rows.slice(currentPage * pageSize, currentPage * pageSize + pageSize);
    const columns = [
        // {
        //     field: 'formatid',
        //     headerName: 'Jobseeker No',
        //     width: 250,
        //     renderCell: (params) => (
        //         <div className='d-flex align-items-center h-100'>
        //             <span className='cursetespoint' style={{color:"#C42B7D"}} onClick={() => handleShow(params.row.id)}>{params.row.formatid}</span>
        //         </div>
        //     ),
        //     filterable: true
        // },
        {  
            field: 'index',
            headerName: '#',
            width: 80,
            filterable: true,
        },
        // {
        //     field: 'contactPhone',
        //     headerName: 'Phone',
        //     width: 250,
        //     filterable: true,
        //     renderCell: (params) => (
        //         <Box className="linklistview h-100 d-flex align-items-center">
        //             <Typography><span><a href={`tel:${params.row.contactPhone}`} target="_blank" rel="noopener noreferrer">{params.row.contactPhone}</a></span></Typography>
        //         </Box>
        //     )
        // },
        {
            field: 'name',
            headerName: 'Name',
            width: 380,
            filterable: true,
        },
        {
            field: 'knowledgebase',
            headerName: 'Knowledge Base',
            width: 380,
            filterable: true,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 200,
            renderCell: (params) => (
                <Grid size={{ xs: 12 }} container alignItems="center" justifyContent="flex-start" className="gap-md-3 h-100 gap-2">
                    <button className='editbtnroot' onClick={() => handleEdit(params.row.id)}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    <button className='trashbtnroot' onClick={() => handleShow(params.row.id)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                </Grid>
            ),
            filterable: true,
        },
    ];
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container justifyContent="space-between" className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Category</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Manage Knowledge <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Category</span>
                    </Grid>
                </Grid>
                <Grid size={{sm:12}} container justifyContent="space-between" className="flex-md-row-reverse">
                    <Grid size={{md:4,xs:12}} container alignContent="flex-start" alignItems="flex-start" className="my-4 knowledge_baseboxes">
                        <h4>{editMode ? "Edit": "Add"} Category</h4>
                        <Grid size={{xs:12}} container justifyContent="flex-end" className="addlister_base mt-2 pt-2">
                            <label>Name<small className='red_starimpo'>*</small></label>
                            <input
                                className='py-4'
                                placeholder='Name'
                                value={name}
                                onChange={handleInputChange} // Handle input change
                            />
                            {nameError && <p style={{ color: 'red', width: '100%', margin: "0px" }}>{nameError}</p>}
                                <label>Knowledge Base<small className='red_starimpo'>*</small></label>
                                <Select
                                    className="basic-single w-100 select_withsearch_ka"
                                    classNamePrefix="select"
                                    value={selectedCategory}
                                    name="category"
                                    options={selectList.map((option) => ({
                                        label: option.name,
                                        value: option.id,
                                    }))} // Map options for the select box
                                    onChange={handleSelectChange} // Handle select change
                                />
                                {categoryError && <p style={{ color: 'red', width: '100%', margin: "0px" }}>{categoryError}</p>}
                                <button onClick={handleSubmit}>
                                {savesbtnloading ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : <>{editMode ? 'Update' : 'Create'}</>}
                                </button>
                                {editMode && (
                                    <button className='cancel_btneditmode' onClick={handleCancel} style={{ marginLeft: '10px' }}>
                                        Cancel
                                    </button>
                                )}
                        </Grid>
                    </Grid>
                    <Grid size={{md:7.8,xs:12}} container justifyContent="flex-end" className="my-4 knowledge_baseboxes">
                        <h4>Category</h4>
                        <Grid size={{md:8,xs:12}} container justifyContent="space-between" alignItems="center" className="mb-2">
                            <Grid size={{xs:12}} container flexDirection="column">
                                <div className='d-flex jobseekersearch my-3'>
                                    <div className='col-2'>
                                        <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                                    </div>
                                    <div className='col-md-7 col-6'>
                                        <input type='text' placeholder='search'
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                  handleSearch(); // Trigger search on Enter key press
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className='col-md-3 col-4'>
                                        <button onClick={handleSearch}>Search</button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div className='overflowwer_fullwidth w-100'>
                            {loading ? 
                                <div className='d-flex justify-content-center w-100'>
                                    <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                                </div> :<>
                                {categorys && categorys.length > 0 ? (
                                    <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
                                        <DataGrid
                                            rows={paginatedRows}
                                            columns={columns}
                                            pageSize={pageSize}
                                            disableSelectionOnClick
                                            components={{ Toolbar: GridToolbar }}
                                        />
                                    </Box>
                                ) : (
                                    <div className="w-100 d-flex justify-content-center mt-4">
                                        <p>No data to display</p>
                                    </div>
                                )}
                            </>}
                        </div>
                        <Grid size={{xs:12}} container className="datatable_jobpagi_indi">
                            <div className='d-flex flex-column w-100 my-3 pagination_datagrid'>
                                <div className='d-flex justify-content-end align-items-center'>
                                    <label>No. of Rows</label>
                                    <select
                                        label="Rows per page"
                                        value={pageSize}
                                        onChange={(e) => setPageSize(Number(e.target.value))}
                                        sx={{ mr: 2 }}
                                    >
                                        {[5, 10, 20, 50].map((size) => (
                                            <option key={size} value={size}>{size}</option>
                                        ))}
                                    </select>
                                </div>
                
                                <div className="d-flex justify-content-center my-3">
                                    <button
                                        className='nexter_btns'
                                        variant="contained"
                                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                                        disabled={currentPage === 0}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                
                                    {/* Page number buttons */}
                                    <div className="d-flex align-items-center mx-2">
                                        {Array.from({ length: Math.ceil(rows.length / pageSize) }, (_, index) => (
                                            <button
                                                key={index}
                                                className={`page-btn ${currentPage === index ? 'active' : ''}`}
                                                onClick={() => setCurrentPage(index)}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}
                                    </div>
                
                                    <button
                                        className='nexter_btns'
                                        variant="contained"
                                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(rows.length / pageSize) - 1))}
                                        disabled={currentPage >= Math.ceil(rows.length / pageSize) - 1}
                                    >
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Category?</Modal.Body>
                <Modal.Footer>
                    <button className='modal_cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='modal_delete' onClick={handleDelete}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default KnowledgeCategory
