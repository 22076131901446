import React, { useEffect } from 'react';
import "./dashboard.css"
import Grid from '@mui/material/Grid2';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { apiService } from '../../services/apiService';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCalendar, faUser } from '@fortawesome/free-regular-svg-icons';

const firebaseConfig = {
  apiKey: "AIzaSyD02rMLZSXId5gXEgowqt-N8f_gPyCYuxU",
  authDomain: "bench-track.firebaseapp.com",
  projectId: "bench-track",
  storageBucket: "bench-track.appspot.com",
  messagingSenderId: "18153078782",
  appId: "1:18153078782:web:1c312dbf6947ccae200aaf",
  measurementId: "G-VG4G8QERNK"
};
function Dashboard() {
  // const [token, setToken] = useState('');

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    // Function to request permission and get token
    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          console.log('Notification permission granted.');

          // Get the FCM token
          const currentToken = await getToken(messaging, { vapidKey: 'BJ4G6kSXuE2mnML3InkqKeCINeNXQyukVAptiyMLH3ZHvfe91ebLgbrX-9uVGJPyVqB1PUks9qxelSRA9PleUys' });
          if (currentToken) {
            console.log('FCM Token:', currentToken);
            // setToken(currentToken);
            sendTokenToServer(currentToken);
            // You can send the token to your server here
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        } else {
          console.log('Unable to get permission to notify.');
        }
      } catch (error) {
        console.error('Error getting permission or token', error);
      }
    };

    // Call the request permission function
    requestPermission();

    // Handle incoming messages
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Show notification in the UI (optional)
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
      };
      new Notification(notificationTitle, notificationOptions);
    });
  }, []);

  const sendTokenToServer = async (token) => {
    try {
      const response = await apiService.postRequest('/save-device-token', { device_token:token });
      console.log('Token saved successfully:', response.data);
    } catch (error) {
      console.error('Error saving token:', error);
    }
  };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{xs:12}} container className="main_jobseeker">
                <Grid size={{xs:12}} container justifyContent="center" className="tempdashboard_main">
                    <Grid size={{md:6,sm:10,xs:12}} container>
                        <img src={require('../../assets/tempdashboard/Banner.svg').default} alt=''/>
                    </Grid>
                    <Grid size={{md:9,sm:10,xs:12}} container className="mt-5">
                        <h5>"Our website is temporarily under maintenance as we work on adding exciting new features and enhancements to improve your experience. We are dedicated to providing you with a seamless and enriched browsing experience, and these updates are part of our commitment to excellence. We’ll be back online shortly—thank you for your understanding and patience!</h5>
                    </Grid>
                    <Grid size={{sm:11,xs:12}} container className="my-5">
                        <iframe width="100%" height="600" src="https://www.youtube-nocookie.com/embed/d9mWh03Z0SA?si=UhNNch1SdY5uG2s5?rel=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    </Grid>
                    {/* <Grid size={{sm:11,xs:12}} container justifyContent="space-between" className="mt-3">
                        <Grid size={{md:3.8,sm:11,xs:12}} container className="mt-5 main_dashblog">
                            <img src={require('../../assets/tempdashboard/blogimg1.png')} alt=''/>
                            <h5>AI tools and strategies to follow for a faster job search</h5>
                            <Grid size={{xs:12}} container className="span_griders mb-2">
                                <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>name01</span>
                            </Grid>
                            <p>In the evolving job market, artificial intelligence is revolutionizing how job seekers and employers</p>
                            <button className='readmore_btn'>Read more</button>
                            <span className='dates_abstop'><FontAwesomeIcon icon={faCalendar} className='icon_tempdash'/>12d ago</span>
                        </Grid>
                        <Grid size={{md:3.8,sm:11,xs:12}} container className="mt-5 main_dashblog">
                            <img src={require('../../assets/tempdashboard/blogimg2.png')} alt=''/>
                            <h5>AI tools and strategies to follow for a faster job search</h5>
                            <Grid size={{xs:12}} container className="span_griders mb-2">
                                <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>name01</span>
                            </Grid>
                            <p>In the evolving job market, artificial intelligence is revolutionizing how job seekers and employers</p>
                            <button className='readmore_btn'>Read more</button>
                            <span className='dates_abstop'><FontAwesomeIcon icon={faCalendar} className='icon_tempdash'/>12d ago</span>
                        </Grid>
                        <Grid size={{md:3.8,sm:11,xs:12}} container className="mt-5 main_dashblog">
                            <img src={require('../../assets/tempdashboard/blogimg3.png')} alt=''/>
                            <h5>AI tools and strategies to follow for a faster job search</h5>
                            <Grid size={{xs:12}} container className="span_griders mb-2">
                                <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>name01</span>
                            </Grid>
                            <p>In the evolving job market, artificial intelligence is revolutionizing how job seekers and employers</p>
                            <button className='readmore_btn'>Read more</button>
                            <span className='dates_abstop'><FontAwesomeIcon icon={faCalendar} className='icon_tempdash'/>12d ago</span>
                        </Grid>
                        
                    </Grid>
                    <Grid size={{xs:12}} container className="mt-5" justifyContent="center">
                    <button className='registersendbtn2'>View More</button>
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default Dashboard
