import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./usergroup.css";
import { faAngleRight, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, Switch } from '@mui/material';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
function UserGroupEdit() {
    const permissionstest = JSON.parse(localStorage.getItem('permissions'));
    const [activeTab, setActiveTab] = useState('member');
    const [memberdata, setMemberData] = useState([]);
    const [submitloader, setSubmitloader] = useState(false);
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 40,
        height: 25,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 15,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#51cc64',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 21,
          height: 21,
          borderRadius: 100,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 100,
          opacity: 1,
          backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));

      const { id } = useParams(); // Retrieve the group ID from the URL
      const [groupData, setGroupData] = useState({ name: '', permissions: [], group_type: ''});
        const navigate = useNavigate();
      useEffect(() => {
        const fetchGroupData = async () => {
          try {
            const response = await apiService.getRequest(`admin/roles/${id}`);
            setGroupData(response.data);
          } catch (error) {
            console.error('Error fetching group data:', error);
          }
        };
    
        fetchGroupData();
      }, [id]);

      const handlePermissionToggle = (tabIndex, permissionIndex, childIndex = null) => {
        const updatedPermissions = [...groupData.permissions];
    
        // Check if it's a parent permission toggle (switch) or child permission toggle (checkbox)
        if (childIndex === null) {
            const currentPermission = updatedPermissions[tabIndex].permissions[permissionIndex];
            currentPermission.enabled = !currentPermission.enabled;
        } else {
            const currentChildPermission = updatedPermissions[tabIndex].permissions[permissionIndex].children[childIndex];
            currentChildPermission.enabled = !currentChildPermission.enabled;
        }
    
        setGroupData({
            ...groupData,
            permissions: updatedPermissions,
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
    
        // Extract the IDs of enabled parent and child permissions
        const enabledPermissionIds = groupData.permissions.flatMap(tab =>
            tab.permissions.flatMap(perm => {
                const parentPermissionIds = perm.enabled ? [perm.id] : [];
                const childPermissionIds = perm.children ? perm.children
                    .filter(childPerm => childPerm.enabled)
                    .map(childPerm => childPerm.id) : [];
                    
                return [...parentPermissionIds, ...childPermissionIds];
            })
        );
    
        const payload = {
            name: groupData?.name || null,
            group_type: groupData?.group_type || null,
            permissions: enabledPermissionIds // Send both parent and child permission IDs
        };
    
        setSubmitloader(true);
        try {
            await apiService.putRequest(`admin/roles/${id}`, payload);
            navigate('/user/group');
            toast("Group Updated Successfully");
        } catch (error) {
            console.error('Error updating group data:', error);
        } finally {
            setSubmitloader(false); // Stop loader
        }
    };
    // const fetchMemberData = async () => {
    //     try {
    //     const response = await apiService.getRequest(`admin/roles`);
    //     const parsedId = parseInt(id, 10);
    //     const filteredGroup = response.data.find(group => group.id === parsedId);
    //     setMemberData(filteredGroup.users);
    //     } catch (error) {
    //     console.error('Error fetching group data:', error);
    //     }
    // };
    const fetchMemberData = useCallback(async () => {
        try {
          const response = await apiService.getRequest(`admin/roles`);
          const parsedId = parseInt(id, 10);
          const filteredGroup = response.data.find((group) => group.id === parsedId);
          setMemberData(filteredGroup?.users || []);
        } catch (error) {
          console.error("Error fetching group data:", error);
        }
      }, [id]);
    useEffect(() => {
        fetchMemberData();
    }, [fetchMemberData]);

    const handleRemove = async (userId) => {
        const removeMemberPayload = {
          group_id: id,
        };
    
        try {
          const response = await apiService.postRequest(`/admin/users/${userId}/remove-group`, removeMemberPayload);
          if (response && response.success === true) {
            toast("Member Removed Successfully");
    
            // Fetch updated member data after successful removal
            fetchMemberData();
          } else {
            console.error("Failed to remove user from group");
          }
        } catch (error) {
          console.error("Error removing user from group:", error);
        }
      };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container justifyContent="center" className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>User</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/group")}>User</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/group")}>Group</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Edit Group</span>
                    </Grid>
                    <Grid size={{xs:12}} container className="editgrouphead my-4">
                        <h2>Edit Group</h2>
                    </Grid>
                </Grid>
                <Grid size={{xs:12}} container justifyContent="center">
                    <Grid size={{md:11,xs:12}} container justifyContent="space-between">
                        <Grid size={{md:5,xs:12}} container flexDirection="column" className="input_groupname">
                            <span>Group Name<small className='red_starimpo'>*</small></span>
                            <input type='text' placeholder='Group Name' value={(groupData && groupData.name) ? groupData.name : ""} 
                            onChange={(e) => setGroupData({ ...groupData, name: e.target.value })}/>
                        </Grid>
                        <Grid size={{md:5,xs:12}} container flexDirection="column" className="input_groupname">
                            <span>Group Type<small className='red_starimpo'>*</small></span>
                            <select value={(groupData && groupData.group_type) ? groupData.group_type : ""} 
                                onChange={(e) => setGroupData({ ...groupData, group_type: e.target.value })}>
                                <option value="">Select</option>
                                <option value="internal">Internal</option>
                                <option value="external">External</option>
                                <option value="hybrid">Hybrid</option>
                            </select>
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="flex-end" className=" gap-md-5 gap-2 mt-4">
                            <button className='cancel_btnbonly' onClick={()=>navigate('/user/group')}>Cancel</button>
                            <button className='save_btnbonly' onClick={handleUpdate}>{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                        </Grid>
                    </Grid>
                    <Grid size={{sm:11,xs:12}} container flexDirection="column" justifyContent="center">
                        {groupData && groupData.permissions.length <= 0 ?
                            <div className='d-flex justify-content-center w-100'>
                                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                            </div> : 
                        <Tabs
                            onSelect={(k) => setActiveTab(k)}
                            id="justify-tab-example"
                            className=" my-4 drafttabs_tabs"
                            >
                                <Tab eventKey="member" title="Member">
                                <Grid size={{xs:12}} container className="tab_membersets mt-0" justifyContent="center" alignContent="flex-start">
                                    <small>Group Members</small>
                                    {memberdata >= 0 ?
                                            <p>No Member to display</p>
                                        :""}
                                    {memberdata && memberdata.map((datas) => (
                                    <Grid size={{xs:11}} container key={datas.id} justifyContent="center" alignItems="center" className="gridtop_editbtn">
                                        
                                        <Grid size={{xs:5}} container alignItems="center">
                                            {datas.profile === "" ? 
                                                <img src={require('../../assets/defaultprofile.svg').default} alt=''/>:
                                                <img src={datas.profile} alt='userdata.name'/>
                                            }
                                            <span>{datas.name}</span>
                                        </Grid>
                                        <Grid size={{xs:5}} container alignItems="center">
                                            <a className='link_mailmember' href={`mailto:${datas.email}`} target="_blank" rel="noopener noreferrer">{datas.email}</a>
                                        </Grid>
                                        <Grid size={{xs:2}} container justifyContent="center">
                                            {id && id === "3" ? "" :<>
                                            {permissionstest && permissionstest.manage_group_reader === true && permissionstest.manage_group_writer === true ? 
                                                <button onClick={() => handleRemove(datas.id)}>Remove</button>
                                            :""}</>}
                                            {/* <button onClick={() => handleRemove(datas.id)} onClick={() => navigate(`/user/member-edit/${datas.id}`)}>Edit</button> */}   
                                        </Grid>
                                    </Grid>
                                    ))}
                                </Grid>
                            </Tab>
                            {groupData && groupData.permissions && Array.isArray(groupData.permissions) && groupData.permissions.map((item,tabIndex) => (
                                <Tab eventKey={item.tab} title={item.tab} key={item.tab} className='w-100'>
                                    <Grid size={{md:12,xs:12}} container justifyContent="center" className="white_editgroup mt-0 mb-4">
                                        <Grid size={{xs:11}} container className="mb-4">
                                            <Grid size={{sm:6,xs:6}} container>
                                                <h6>Title</h6>
                                            </Grid>
                                            {activeTab !== 'User Control' && (
                                                <Grid size={{sm:2,xs:6}} container className="justify-content-md-center justify-content-end">
                                                    <h6>Show</h6>
                                                </Grid>
                                            )}
                                            
                                        </Grid>
                                        {item.permissions.map((permData, permissionIndex)=>(
                                            <Grid size={{sm:11,xs:11}} container className="mb-4" key={permData.id} alignItems="flex-start">
                                                <Grid size={activeTab === 'User Control' ? { sm: 12, xs: 12 } : { sm: 6, xs: 10 }} container flexDirection="column">
                                                    <span className='page_span'>{permData.icon && <img src={permData.icon} alt=''/>}{permData.display_name}</span>
                                                    {permData.children.length > 0 ? (
                                                        permData.children.map((innerids,childIndex) => (
                                                            <div key={innerids.id} className='border_checkbox'>
                                                                <small className='checkbox_smallcolor mt-0'><input type='checkbox' onChange={() => handlePermissionToggle(tabIndex, permissionIndex,childIndex)} checked={innerids.enabled}/>{innerids.display_name}</small>
                                                                <small className='mt-2 ms-4'>{innerids.description}</small>
                                                            </div>
                                                        ))): (
                                                            ""
                                                        )}
                                                </Grid>
                                                {activeTab !== 'User Control' && (
                                                <Grid size={{sm: 2, xs: 2}} container justifyContent="center" alignItems="center">
                                                <AntSwitch
                                                    checked={permData.enabled}
                                                    onChange={() => handlePermissionToggle(tabIndex, permissionIndex)}  // Handle switch toggle
                                                    inputProps={{ 'aria-label': 'ant design' }}
                                                />
                                                <OverlayTrigger
                                                    placement="right" // You can change the position (top, bottom, left, right)
                                                    className='group_tooltips'
                                                    overlay={
                                                        <Tooltip id="tooltip-top" className='group_tooltips'>
                                                        {permData.description || "No Details"}
                                                        </Tooltip>
                                                    }
                                                    >
                                                    <div className='tooltip_divs'>
                                                        <FontAwesomeIcon className='tooltip_icons' icon={faExclamation} />
                                                    </div>
                                                </OverlayTrigger>
                                                </Grid>
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Tab>
                            ))}
                        </Tabs>
                        }
                    </Grid>
                </Grid>
                {activeTab !== 'member' && (
                <Grid size={{sm:11}} container className="ps-md-5 gap-md-5 gap-2 mb-4">
                    <button className='cancel_btnbonly' onClick={()=>navigate('/user/group')}>Cancel</button>
                    <button className='save_btnbonly' onClick={handleUpdate}>{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                </Grid>
                )}
            </Grid>
        </div>
    </div>
  )
}

export default UserGroupEdit
