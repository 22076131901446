import React, { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import Grid from '@mui/material/Grid2';
import "./knowledge.css";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Select from 'react-select';
import { apiService } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
function DraftAdd() {
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const authorName = localStorage.getItem('usernames');
    const [catsShows, setCatsshow] = useState(false);
    const [subCatsShows, setSubCatsshow] = useState(false);
    const [baseids, setBaseid] = useState([]);
    const [taskType, setTaskType] = useState([]);
    const [categoryId, setCategoryid] = useState([]);
    const [subcategoryId, setSubCategoryid] = useState([]);
    const [selectedBaseid, setSelectedBaseid] = useState(null);
    const [selectedCatId, setSelectedCategoryid] = useState(null);
    const [selectedTask, setSelectedTask] = useState([]);
    const [selectedsubCatId, setSelectedsubCategoryid] = useState(null);
    const [previewer, setPreview] = useState("");
    const tomorrow = dayjs().add(1, 'day');
    const [submitloader, setSubmitloader] = useState("");
    const [value, setValue] = useState(dayjs(null));
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [files, setFiles] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [newName, setNewName] = useState('');
    const [error, setError] = useState('');
    const [backActive, setBackActive] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const fileInputRef = useRef(null);

    const MAX_FILES = 10;
    const MAX_TOTAL_SIZE_MB = 2500;
    const MAX_FILE_SIZE_MB = 250;
    const allowedTypes = [
        'image/jpeg', 'image/png', 'image/gif',
        'application/pdf',
        'text/csv',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    const fetchSuggestions = async (query) => {
        if (!query) {
          setSuggestions([]); // Clear suggestions if input is empty
          return;
        }
    
        try {
          const response = await apiService.postRequest("knowledge-article/tag-suggestions", { query });
          if(response && response.success){
              setSuggestions(response.tags || []); // Adjust based on API response
          }
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
    };
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);

        // Check if total number of files exceeds limit
        if (files.length + selectedFiles.length > MAX_FILES) {
            setError(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        // Check each file size and type
        for (const file of selectedFiles) {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                return;
            }
            if (!allowedTypes.includes(file.type)) {
                setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                return;
            }
        }

        // Check total combined size of all files
        const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
        const newSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
        if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
            return;
        }

        setError('');
        const updatedFiles = selectedFiles.map((file) => ({
            file,
            name: file.name,
        }));
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
        fileInputRef.current.value = null;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);

        // Check if total number of files exceeds limit
        if (files.length + droppedFiles.length > MAX_FILES) {
            setError(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        // Check each file size and type
        for (const file of droppedFiles) {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                return;
            }
            if (!allowedTypes.includes(file.type)) {
                setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                return;
            }
        }

        // Check total combined size of all files
        const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
        const newSize = droppedFiles.reduce((acc, file) => acc + file.size, 0);
        if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
            return;
        }

        setError('');
        const updatedFiles = droppedFiles.map((file) => ({
            file,
            name: file.name,
        }));
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    };


    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        // Reset the file input to allow the same file to be uploaded again
        fileInputRef.current.value = null;
    };

    const handleRenameFile = (index) => {
        const originalName = files[index].file.name;
        const fileExtension = originalName.split('.').pop();
        const updatedName = newName ? `${newName}.${fileExtension}` : originalName;
        
        setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles[index].name = updatedName;
            return updatedFiles;
        });

        setEditIndex(null); // Close the edit popup
        setNewName(''); // Reset the new name
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };


    const [formData, setFormData] = useState({
        title: '',
        retired_date: '',
        status: '',
        tags:"",
        task_type: [],
        base_id: '',
        category_id: '',
        subcategory_id: '',
        content: '',
    });
    
    const [tags, setTags] = useState([]);
    const [input, setInput] = useState("");

    const handleInputChanger = (e) => {
        setInput(e.target.value);
        fetchSuggestions(e.target.value);
        if (errors.tags) {
            setErrors(prevErrors => ({ ...prevErrors, tags: undefined }));
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && input.trim()) {
            e.preventDefault();
            const newTag = input.trim();
            if (!tags.includes(newTag)) {
                const updatedTags = [...tags, newTag];
                setTags(updatedTags);

                // Update the formData tag property to a comma-separated string or join the tags array as needed
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    tags: updatedTags.join(", "), // Join tags into a single string if needed
                }));
            }else{
                setSuggestions([]);
            }
            setInput("");
        }
    };
    const handleSuggestionClick = (suggestion) => {
        if (!tags.includes(suggestion)) {
          setTags([...tags, suggestion]);
        }
        setInput(""); // Clear input
        setSuggestions([]); // Hide suggestions
      };
    const removeTag = (index) => {
        const updatedTags = tags.filter((tag, i) => i !== index);
        setTags(updatedTags);

        // Update the formData tag property to reflect the removed tag
        setFormData((prevFormData) => ({
            ...prevFormData,
            tag: updatedTags.join(", "), // Update to a comma-separated string if needed
        }));
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Clear the error for the specific field being modified
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({ ...formData, [name]: value });
    };
    const handleDateChange = (newValue) => {
        if (newValue) {
            setValue(newValue);
            setFormData({ ...formData, retired_date: newValue.format('YYYY-MM-DD') });
    
            // Clear the error for retired_date if a valid date is entered
            if (errors.retired_date) {
                setErrors({ ...errors, retired_date: '' });
            }
        } else {
            setValue(null);
            setFormData({ ...formData, retired_date: '' }); // Clear the value if null
        }
    };

    const handleEditorChange = (htmlContent) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: htmlContent,
        }));

        // Clear content error if the user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            content: '',
        }));
    };
    useEffect(() => {
        if (formData.title === "") {
            setBackActive(true);
        }else{
            setBackActive(false);
        }
    }, [formData.title]);
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.getRequest('knowledge-bases-with-task-types');
            if (response.success) {
                setBaseid(response.data);
                setTaskType(response.taskTypes);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (formData.base_id) {
            const fetchData = async () => {
                const response = await apiService.getRequest(`knowledge-article-categories-list/${formData.base_id}`);
                if (response.success) {
                    setCategoryid(response.data || []);
                    setSelectedCategoryid(null);
                }
            };
            fetchData();
        }
    }, [formData.base_id]);

    useEffect(() => {
        if (formData.category_id) {
            const fetchData = async () => {
                const response = await apiService.getRequest(`knowledge-article-subcategories-list/${formData.category_id}`);
                if (response.success) {
                    setSubCategoryid(response.data || []);
                    setSelectedsubCategoryid(null);
                }
            };
            fetchData();
        }
    }, [formData.category_id]);

    const handlebaseidChange = (selectedOption) => {
        setSelectedBaseid(selectedOption);
        handleInputChange({
            target: {
                name: 'base_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
        setSelectedCategoryid(null);
    };

    const handleCatidChange = (selectedOption) => {
        setSelectedCategoryid(selectedOption); // Update city
        handleInputChange({
            target: {
                name: 'category_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
        setSelectedsubCategoryid(null);
    };
    useEffect(() => {
        if (selectedBaseid) {
            setCatsshow(true);
        }else{
            setCatsshow(false);
        }
        if (selectedCatId) {
            setSubCatsshow(true);
        }else{
            setSubCatsshow(false);
        }
    }, [selectedBaseid,selectedCatId]);
    const handleTaskChange = (selectedOptions) => {
        setSelectedTask(selectedOptions); // Update the state for selected tasks
    
        // Update the formData with the selected task types as an array
        setFormData(prevFormData => ({
            ...prevFormData,
            task_type: selectedOptions ? selectedOptions.map(option => option.label) : [], // Store as an array
        }));
    };
    const handleSubCatidChange = (selectedOption) => {
        setSelectedsubCategoryid(selectedOption);
        handleInputChange({
            target: {
                name: 'subcategory_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };
    const validateForm = () => {
        let valid = true;
        const errors = {};
        
        if (!formData.title || formData.title.trim() === '') {
            errors.title = 'Title is required.';
            valid = false;
        }
    
        // Custom validation for retired_date (e.g., required and valid date format)
        if (!formData.retired_date || formData.retired_date.trim() === '') {
            errors.retired_date = 'Retired date is required.';
            valid = false;
        } else if (!dayjs(formData.retired_date, 'YYYY-MM-DD', true).isValid()) {
            errors.retired_date = 'Invalid date format. Use YYYY-MM-DD.';
            valid = false;
        }

        if (!Array.isArray(formData.task_type) || formData.task_type.length === 0) {
            errors.task_type = 'At least one task type is required.';
            valid = false;
        }
    
        // Custom validation for base_id (e.g., required field)
        if (!formData.base_id) {
            errors.base_id = 'Base ID is required.';
            valid = false;
        }
    
        // Custom validation for category_id (e.g., required field)
        if (!formData.category_id) {
            errors.category_id = 'Category ID is required.';
            valid = false;
        }
    
        // Custom validation for subcategory_id (e.g., required field)
        if (!formData.subcategory_id) {
            errors.subcategory_id = 'Subcategory ID is required.';
            valid = false;
        }
    
        // Custom validation for content (e.g., required field)
        if (!formData.content || formData.content.trim() === '') {
            errors.content = 'Content is required.';
            valid = false;
        }
    
        if (!tags.length) {
            errors.tags = 'At least one tag is required.';
            valid = false;
        }
        // Set errors if any field is invalid
        setErrors(errors);
    
        return valid;  // Return true if valid, false otherwise
    };
    const handleSubmit = async (e, status, previewer, saveOnly = false) => {
        e.preventDefault();
        if(!saveOnly){
            if (!validateForm()) {
                setErrorMessage('Please fix the errors before proceeding.');
                return;
            }
        }
    
        setPreview(previewer);
        setSubmitloader(status);
    
        try {
            
            const formDataToSend = new FormData();

            for (const key in formData) {
                if (Array.isArray(formData[key])) {
                    
                    formData[key].forEach(value => formDataToSend.append(key + '[]', value));
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            }
            
            formDataToSend.append('tags', tags.join(", "));
            
            files.forEach((file) => {
                formDataToSend.append('documents[]', file.file);
                formDataToSend.append(`document_name[]`, file.name);
            });
    
            
            formDataToSend.append('status', status);
            const response = await apiService.postRequest('knowledge-article', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response && response.success === true) {
                setErrors({});
                setErrorMessage('');
    
                const isPreviewer = previewer === "true";
    
                if (isPreviewer && response.data.status === "draft") {
                    navigate(`/knowledge/preview/${response.data.id}`);
                    toast('Article Saved to Draft successfully!');
                }else if( response.data.status ==="draft" ){
                    navigate(`/knowledge/edit-article/${response.data.id}`);
                    toast('Article Saved to Draft successfully!');
                }else if( status ==="under_approval" ){
                    navigate('/knowledge/under-approval');
                    toast('Article Moved to Approval successfully!');
                }else {
                    setBackActive(true);
                }
    
            } else {
                setErrorMessage(response.message ,'Save failed. Please try again.');
                setErrors(response.errors || 'Save failed. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            setErrorMessage('An error occurred while submitting the form.');
        }finally{
            setSubmitloader("");
        }
    };
    
    const styles = {
        tagContainer: {
            display: "flex",
            flexWrap: "wrap",
            padding: "0px",
            border: "none",
            borderRadius: "5px",
        },
        tag: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "#B8D7FF",
            borderRadius: "3px",
            padding: "5px",
            marginRight: "5px",
            marginBottom: "5px",
            fontWeight:"600",
        },
        removeButton: {
            marginLeft: "5px",
            border: "none",
            background: "transparent",
            cursor: "pointer",
            fontSize: "16px",
        },
    };
        
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Draft</h3>
                    </Grid>
                </Grid>
                <Grid size={{xs:12}} container flexDirection="column" className="my-4">
                    <Tabs
                    defaultActiveKey="article"
                    id="uncontrolled-tab-example"
                    className="mb-3 drafttabs_tabs"
                    >
                    <Tab eventKey="article" title="Article">
                        <Grid size={{xs:12}} container justifyContent="space-between">
                        <form className='w-100' 
                        onSubmit={handleSubmit}
                        >
                            <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="darfts_inputs">
                                <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="gap-md-4 gap-2">
                                <button
                                    style={{ width: "max-content" }}
                                    className="registersendbtn2 px-4"
                                    type="submit"
                                    onClick={(e) => handleSubmit(e, "under_approval", "false", false)}
                                    >
                                    {submitloader === "under_approval" ? (
                                        <img src={require('../../assets/whiteloader.gif')} alt='' />
                                    ) : (
                                        'Request for Approval'
                                    )}
                                    </button>

                                    <button
                                    className="registersendbtn1"
                                    type="submit"
                                    onClick={(e) => {handleSubmit(e, "draft", "false", true);}}
                                    >
                                    {(submitloader === "draft" && previewer === "false") ? (
                                        <img src={require('../../assets/pinkloader.gif')} alt='' />
                                    ) : (
                                        'Save'
                                    )}
                                    </button>

                                    <button
                                        className="registersendbtn1"
                                        onClick={() => {
                                            if(backActive){
                                                navigate("/knowledge/draft");
                                            }else{
                                                setShow(true);
                                            }
                                        }}
                                    >
                                    Back
                                    </button>

                                    <button
                                    className="registersendbtn1"
                                    type="submit"
                                    onClick={(e) => handleSubmit(e, "draft", "true", false)}
                                    >
                                    {(submitloader === "draft" && previewer === "true") ? (
                                        <img src={require('../../assets/pinkloader.gif')} alt='' />
                                    ) : (
                                        'Preview'
                                    )}
                                    </button>
                                </Grid>
                                <Grid size={{xs:12}} container flexDirection="column" className="author_spans">
                                    <span className='mt-0'>Author</span>
                                    <h5>{authorName}</h5>
                                </Grid>
                                <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                    <span>Title<small>*</small></span>
                                    <input
                                        type="text"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        // onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                    />
                                    {errors.title && <p style={{ color: "Red" }}>{errors.title}</p>}
                                </Grid>
                                <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                    <span>Retire Date<small>*</small></span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                className="date_pickermux"
                                                value={value}
                                                onChange={handleDateChange}
                                                minDate={tomorrow}
                                                renderInput={(params) => <input {...params} />}
                                            />
                                        </LocalizationProvider>
                                        {errors.retired_date && <p style={{ color: "Red" }}>{errors.retired_date}</p>}
                                </Grid>
                                <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                    <span>Version<small>*</small></span>
                                    <input type='text' value={1} disabled/>
                                </Grid>
                                <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                    <span>Knowledge Base<small>*</small></span>
                                    <Select
                                        className="basic-single select_withsearch"
                                        classNamePrefix="select"
                                        value={selectedBaseid}
                                        defaultValue={null}
                                        name="base_id"
                                        options={baseids && baseids.map((state) => ({
                                            label: state.name,
                                            value: state.id,
                                        }))}
                                        onChange={handlebaseidChange}
                                    />
                                    {errors.base_id && <p style={{color:"Red"}}>{errors.base_id}</p>}
                                </Grid>
                                <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                    <span>Select all applicable task types<small>*</small></span>
                                    <Select
                                        isMulti
                                        className="basic-single select_withsearch"
                                        classNamePrefix="select"
                                        value={selectedTask}
                                        defaultValue={null}
                                        name="task_type"
                                        options={taskType && taskType.map((task) => ({
                                            label: task.name,
                                            value: task.name,
                                        }))}
                                        onChange={handleTaskChange}
                                    />
                                    {errors.task_type && <p style={{color:"Red"}}>{errors.task_type}</p>}
                                </Grid>
                                {catsShows &&
                                <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                    <span>Category<small>*</small></span>
                                    <Select
                                        className="basic-single select_withsearch"
                                        classNamePrefix="select"
                                        value={selectedCatId}
                                        defaultValue={null}
                                        name="category_id"
                                        options={categoryId.map((cates) => ({
                                            label: cates.name,
                                            value: cates.id,
                                        }))}
                                        onChange={handleCatidChange}
                                    />
                                    {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>}
                                </Grid>}
                                {subCatsShows &&
                                <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                                    <span>Sub Category<small>*</small></span>
                                    <Select
                                        className="basic-single select_withsearch"
                                        classNamePrefix="select"
                                        value={selectedsubCatId}
                                        defaultValue={null}
                                        name="subcategory_id"
                                        options={subcategoryId.map((subcates) => ({
                                            label: subcates.name,
                                            value: subcates.id,
                                        }))}
                                        onChange={handleSubCatidChange}
                                    />
                                    {errors.subcategory_id && <p style={{color:"Red"}}>{errors.subcategory_id}</p>}
                                </Grid>}
                                <Grid size={{xs:12}} container className="mt-5">
                                <Editor
                                    apiKey='spl4mbm1ksgtbx9txgs350v68n29xmoqkagakiens2wif5jb'
                                    onEditorChange={handleEditorChange}
                                    onInit={(_evt, editor) => editorRef.current = editor}
                                    placeHolder="Enter the content"
                                    init={{
                                        height: 500,
                                        menubar: 'file edit view insert format tools table',

                                        // Custom menu configuration to include all options
                                        menu: {
                                        file: { title: 'File', items: 'newdocument restoredraft | preview | print | deleteallconversations' },
                                        edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                                        view: { title: 'View', items: 'code | visualaid visualblocks visualchars | preview fullscreen' },
                                        insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap hr pagebreak nonbreaking anchor toc' },
                                        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' },
                                        tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                                        table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' }
                                        },

                                        plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | formatselect | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'image inserttable table | removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        tinymceScriptSrc: "https://cdn.jsdelivr.net/npm/tinymce@6.0.3/tinymce.min.js",
                                        placeholder: 'Start typing your content here...'
                                    }}
                                />
                                    {errors.content && <p style={{color:"Red"}}>{errors.content}</p>}
                                </Grid>
                                <Grid size={{sm:12, xs:12}} container flexDirection="column" style={{position:"relative"}}>
                                    <span>Tag<small>*</small></span>
                                    <input
                                        type="text"
                                        value={input}
                                        onChange={handleInputChanger}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Type and press Enter"
                                        style={styles.input}
                                    />
                                    {suggestions.length > 0 && (
                                    <ul style={{ 
                                        listStyle: "none", 
                                        padding: "5px", 
                                        border: "1px solid #ccc", 
                                        borderRadius: "4px", 
                                        margin: 0, 
                                        position: "absolute", 
                                        top:"100%",
                                        background: "#fff", 
                                        width: "250px",
                                        height:"150px",
                                        overflow:"auto",
                                        zIndex: 10, 
                                    }}>
                                        {suggestions.map((tag, index) => (
                                            <li 
                                                key={index} 
                                                style={{ padding: "5px", cursor: "pointer" }} 
                                                onClick={() => handleSuggestionClick(tag)}
                                            >
                                                {tag}
                                            </li>
                                            ))}
                                        </ul>
                                    )}
                                    <div style={styles.tagContainer}>
                                        {tags.map((tag, index) => (
                                        <div key={index} style={styles.tag}>
                                            {tag}
                                            <button
                                            type="button"
                                            style={styles.removeButton}
                                            onClick={() => removeTag(index)}
                                            >
                                            &times;
                                            </button>
                                        </div>
                                        ))}
                                    </div>
                                    {errors.tags && <p style={{color:"Red"}}>{errors.tags}</p>}
                                </Grid>
                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                <Grid size={{sm:12, xs:12}} container justifyContent="flex-start" className="gap-md-4 gap-2">
                                <button
                                    style={{ width: "max-content" }}
                                    className="registersendbtn2 px-4"
                                    type="submit"
                                    onClick={(e) => handleSubmit(e, "under_approval", "false", false)}
                                    >
                                    {submitloader === "under_approval" ? (
                                        <img src={require('../../assets/whiteloader.gif')} alt='' />
                                    ) : (
                                        'Request for Approval'
                                    )}
                                    </button>

                                     <button
                                    className="registersendbtn1"
                                    type="submit"
                                    onClick={(e) => {handleSubmit(e, "draft", "false", true)}}
                                    >
                                    {(submitloader === "draft" && previewer === "false") ? (
                                        <img src={require('../../assets/pinkloader.gif')} alt='' />
                                    ) : (
                                        'Save'
                                    )} 
                                    </button>

                                    <button
                                    className="registersendbtn1"
                                    onClick={() => {
                                        if(backActive){
                                            navigate("/knowledge/draft");
                                        }else{
                                            setShow(true);
                                        }
                                    }}
                                    >
                                    Back
                                    </button>
                                    <button
                                    className="registersendbtn1"
                                    type="submit"
                                    onClick={(e) => handleSubmit(e, "draft", "true", false)}
                                    >
                                    {(submitloader === "draft" && previewer === "true") ? (
                                        <img src={require('../../assets/pinkloader.gif')} alt='' />
                                    ) : (
                                        'Preview'
                                    )}
                                    </button>
                                </Grid>
                            </Grid>
                        </form>
                        </Grid>
                    </Tab>                    
                    <Tab eventKey="attachments" title="Attachments">
                        <Grid size={{xs:12}} container justifyContent="center" className="mt-4">
                            <Grid size={{md:8,xs:12}} container>
                                <div
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                    className='drag_uploaders align-items-center flex-column d-flex'
                                >
                                    <FontAwesomeIcon className='file_uploadericon' icon={faFileLines} />
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        id="file-upload"
                                        ref={fileInputRef} // Attach the ref here
                                    />
                                    <label htmlFor="file-upload">
                                        Browse Files
                                    </label>
                                    <p className='w-100 mb-0 mt-3'>or drop file here</p>
                                </div>
                                {error && <div style={{ color: 'red', margin: '10px 0px' }}>{error}</div>}
                            </Grid>
                            <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                                <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4 attach_headerspan">
                                    <Grid size={{sm:2,xs:3}} container>
                                        <span>#</span>
                                    </Grid>
                                    <Grid size={{sm:8,xs:6}} container>
                                        <span>File Name</span>
                                    </Grid>
                                    <Grid size={{sm:2,xs:3}} container>
                                        <span>Actions</span>
                                    </Grid>
                                </Grid>
                                {files.map((file, index) => (
                                    <Grid key={index} size={{xs:12}} container justifyContent="space-between" alignItems="center" className="tablelisterattach">
                                        <Grid size={{sm:2,xs:3}} container>
                                            <span>{index + 1}</span>
                                        </Grid>
                                        <Grid size={{sm:8,xs:6}} container>
                                            {editIndex === index ? (
                                                <input
                                                    className='rename_inputer'
                                                    type="text"
                                                    value={newName}
                                                    onChange={(e) => setNewName(e.target.value)}
                                                    placeholder="Enter new name"
                                                    style={{ width: '80%' }}
                                                />
                                            ) : (
                                                <span className='filenamesshow'>{file.name}</span>
                                            )}
                                        </Grid>
                                        <Grid size={{sm:2,xs:3}} container>
                                            {editIndex === index ? (
                                                <button className='change_btnattach' onClick={() => handleRenameFile(index)}>Change</button>
                                            ) : (
                                                <>
                                                    <button onClick={() => {
                                                        setEditIndex(index);
                                                        setNewName(file.name.split('.').slice(0, -1).join('.')); // Set the base name for editing
                                                    }}>
                                                        <FontAwesomeIcon icon={faPenToSquare} />
                                                    </button>
                                                    <button onClick={() => handleRemoveFile(index)}>
                                                        <FontAwesomeIcon icon={faXmark} />
                                                    </button>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Tab>
                </Tabs>
                </Grid>
            </Grid>
            <Modal
                show={show} 
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{border:"none"}}>
                    <h4 className='mb-0'>Leave Page</h4>
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex w-100 flex-column modal_register">
                    <h5>Do you want to save changes before leaving this page?</h5>
                    <p>Your changes will be lost if you leave now.</p>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <button className='blue_primarymodalbtn1' onClick={handleClose}>
                    Cancel
                </button>
                <button className='blue_primarymodalbtn2' onClick={() => navigate("/knowledge/draft")}>
                    Leave
                </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default DraftAdd
