import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid2';
import './knowledge.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faEye, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';

function Attachments() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const { id } = useParams();
    const [originalName, setOriginalName] = useState("");
    const [files, setFiles] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [newName, setNewName] = useState('');
    const [error, setError] = useState('');
    const [catesNames,setCateNames] = useState('');
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [articleDetail, setArticleDetail] = useState([]);
    const author_ids = Number(localStorage.getItem('userid'));
    const [authorDetail,setAuthorDetail] = useState('');
    const isAuthor = (authorDetail && authorDetail.id === author_ids && articleDetail.status === "draft");
    useEffect(() => {
        const fetchPreviewData = async () => {
            try {
                const response = await apiService.getRequest(`knowledge-article/${id}`);
                if (response && response.success) {
                    setCateNames(response.data.subcategory);
                    setAuthorDetail(response.data.author);
                    setArticleDetail(response.data);
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        };

        fetchPreviewData();
    }, [id]);
    const uploadFiles = async (allFiles) => {
        const formData = new FormData();
        
        // Append existing documents to formData
        documents.forEach(doc => {
            formData.append('existingDocuments[]', doc.id);
        });
        
        // Append newly added files to formData
        allFiles.forEach(file => {
            formData.append('document', file.file);
        });
        setLoading(true);
        try {
            const response = await apiService.postRequest(`/knowledge-articles/${id}/documents`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response && response.success === true) {
                setFiles([]);
                setDocuments(response.data.documents);
            }
        } catch (error) {
            // console.error('Error uploading documents:', error);
        }finally{
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchDocuments = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`/knowledge-articles/${id}/documents`);
                if (response && response.success === true) {
                    setDocuments(response.data.documents);
                }
            } catch (error) {
                console.error('Error fetching documents:', error);
                setError('Failed to fetch documents.');
            }finally{
                setLoading(false);
            }
        };
        fetchDocuments();
    }, [id,files]);
    
    const MAX_FILES = 10; // Maximum number of files that can be uploaded
    const MAX_TOTAL_SIZE_MB = 2500; // Maximum total size for all uploads
    const MAX_FILE_SIZE_MB = 250; // Maximum size for each individual file
    const allowedTypes = [
        'image/jpeg', 'image/png', 'image/gif', // image files
        'application/pdf', // pdf files
        'text/csv', // csv files
        'application/msword', // doc files
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // docx files
    ];

    const handleFileChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);

        // Check if total number of files exceeds limit
        if (files.length + selectedFiles.length > MAX_FILES) {
            setError(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        // Check each file size and type
        for (const file of selectedFiles) {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                return;
            }
            if (!allowedTypes.includes(file.type)) {
                setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                return;
            }
        }

        // Check total combined size of all files
        const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
        const newSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
        if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
            return;
        }

        setError('');
        const updatedFiles = selectedFiles.map((file) => ({ file, name: file.name }));
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
        fileInputRef.current.value = null;

        // Call the function to upload files after state update
        await uploadFiles([...files, ...updatedFiles]);
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);

        // Check if total number of files exceeds limit
        if (files.length + droppedFiles.length > MAX_FILES) {
            setError(`You can only upload up to ${MAX_FILES} files.`);
            return;
        }

        // Check each file size and type
        for (const file of droppedFiles) {
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                return;
            }
            if (!allowedTypes.includes(file.type)) {
                setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                return;
            }
        }

        // Check total combined size of all files
        const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
        const newSize = droppedFiles.reduce((acc, file) => acc + file.size, 0);
        if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
            setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
            return;
        }

        setError('');
        const updatedFiles = droppedFiles.map((file) => ({ file, name: file.name }));
        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

        // Call the function to upload files after state update
        await uploadFiles([...files, ...updatedFiles]);
    };

    const handleRemoveFile = async (index) => {
        const documentId = documents[index].id;
        setLoading(true);
        try {
            const response = await apiService.deleteRequest(`/knowledge-articles/documents/${documentId}`);
            if(response && response.success === true){
                setDocuments((prevDocs) => prevDocs.filter((_, i) => i !== index));
                setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                toast("Document Deleted Successfully")
            }
        } catch (error) {
            console.error('Error deleting document:', error);
            setError('Failed to delete document.');
        }
        finally{
            setLoading(false);
        }
    };

    const handleRenameFile = async (index) => {
        if (typeof index === "number" && index >= 0 && index < documents.length) {
            const updatedDocuments = documents.map((doc, i) =>
                i === index ? { ...doc, document_name: newName } : doc
            );
            setDocuments(updatedDocuments);
            setEditIndex(null);
            setNewName("");
            setLoading(true);
            try {
                // Call the API to update the document name in the backend
                const documentId = documents[index].id;
               const response = await apiService.postRequest(`/knowledge-articles/documents/${documentId}`, {
                    document_name: newName,
                });

                if(response && response.success === true){
                    toast("Document Renamed Successfully")
                }
            } catch (error) {
                console.error('Error renaming document:', error);
            }finally{
                setLoading(false);
            }
        } else {
            setNewName(originalName);
            setEditIndex(null);
        }
    };
    
    
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <div className='container-fluid px-0'>
            <div className='content_container'>
                <Grid size={{ sm: 12 }} container className="main_jobseeker">
                    <Grid size={{sm:12}} container alignItems="center" className="mario_fixers_article">
                        {articleDetail && articleDetail.status === "under_approval" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Under Approval</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "draft" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Edit Draft</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "published" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Published</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "retired" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Retired</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                    </Grid>
                    {loading ? (
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                        </div>
                    ) : (<>
                    <Grid size={{ xs: 12 }} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button className={currentPath.startsWith("/knowledge/edit-article") ? "active_tabbtn" : ""} onClick={() => navigate(`/knowledge/edit-article/${id}`)}>Article</button>
                            {articleDetail && articleDetail.status === "draft" ? "":
                            <button onClick={() => navigate(`/knowledge/under-approval/show/${id}`)}>Approvals</button>}
                            <button onClick={() => navigate(`/knowledge/under-approval/history/${id}`)}>History</button>
                            <button className={currentPath.startsWith('/knowledge/attachments') ? "active_tabbtn" : ""} onClick={() => navigate(`/knowledge/attachments/${id}`)}>Attachments</button>
                            <button onClick={() =>{navigate(`/knowledge/discussions/${id}`)}}>Discussions</button>
                            <button onClick={() =>{navigate(`/knowledge/versions/${id}`)}}>Versions</button>
                        </div>
                    </Grid>
                    <Grid size={{ xs: 12 }} container justifyContent="center" className="mt-4">
                        {isAuthor && 
                            <Grid size={{ md:8,xs: 12 }} container justifyContent="space-between" className="mt-4">
                            <div
                                onDrop={documents.length >= MAX_FILES || !isAuthor ? undefined : handleDrop}
                                onDragOver={(e) => {
                                    if (documents.length >= MAX_FILES || !isAuthor) {
                                        e.preventDefault();
                                        return;
                                    }
                                    handleDragOver(e);
                                }}
                                className={`drag_uploaders align-items-center flex-column d-flex ${
                                    documents.length >= MAX_FILES || !isAuthor ? 'disabled' : ''
                                }`}
                            >
                                    <FontAwesomeIcon className='file_uploadericon' icon={faFileLines} />
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        id="file-upload"
                                        ref={fileInputRef}
                                        disabled={documents.length >= MAX_FILES || !isAuthor}
                                    />
                                    <label htmlFor="file-upload">Browse Files</label>
                                    <p className='w-100 mb-0 mt-3'>or drop file here</p>
                                </div>
                            {error && <div style={{ color: 'red', margin: '10px 0px' }}>{error}</div>}
                            </Grid>
                        }
                        <Grid size={{ xs: 12 }} container justifyContent="space-between" className="mt-4">
                            <Grid size={{ xs: 12 }} container className="mt-4 attach_headerspan">
                                <Grid size={{ sm: 2, xs: 3 }} container>
                                    <span>#</span>
                                </Grid>
                                <Grid size={{ sm: 8, xs: 6 }} container>
                                    <span>File Name</span>
                                </Grid>
                                {isAuthor && 
                                    <Grid size={{ sm: 2, xs: 3 }} container>
                                        <span>Actions</span>
                                    </Grid>
                                }
                            </Grid>
                            
                            {Array.isArray(documents) && documents.length > 0 ? (
                                documents.map((doc, index) => (
                                <Grid key={doc.id} size={{ xs: 12 }} container justifyContent="flex-start" alignItems="center" className="tablelisterattach">
                                    <Grid size={{ sm: 2, xs: 3 }} container>
                                        <span>{index + 1}</span>
                                    </Grid>
                                    <Grid size={{ sm: 8, xs: 6 }} container>
                                        {/* <a href={doc.file_path} target="_blank" rel="noopener noreferrer">View Document</a> */}
                                        { editIndex === index ? (
                                        <Grid container size={{xs:12}} className="">
                                            <input
                                            className='rename_inputer'
                                                type="text"
                                                value={newName}
                                                onChange={(e) => {
                                                    setNewName(e.target.value);
                                                    if (files[index]) {
                                                        setOriginalName(files[index].name);
                                                    }
                                                }}
                                                placeholder="New document name"
                                            />
                                            <button onClick={() => handleRenameFile(index)}>Save</button>
                                            <button onClick={() => handleRenameFile("")}>Cancel</button>
                                        </Grid>
                                    ):<span className='filenamesshow'>{doc.document_name || 'Untitled'}</span>}
                                    </Grid>
                                    {isAuthor && isAuthor ? 
                                        <Grid size={{ sm: 2, xs: 3 }} container alignItems="center">
                                            <button onClick={() => handleRemoveFile(index)} disabled={!isAuthor}>
                                                <FontAwesomeIcon icon={faXmark} />
                                            </button>
                                            <button onClick={() => {
                                                setEditIndex(index);
                                                setNewName(doc.document_name || '');
                                            }} disabled={!isAuthor}>
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </button>
                                            <a href={`${doc.file_path}`} target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faEye} />
                                            </a>
                                        </Grid> :
                                        <Grid size={{ sm: 2, xs: 3 }} container alignItems="center">
                                        <a href={`${doc.file_path}`} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faEye} />
                                        </a>
                                    </Grid>
                                    }
                                </Grid>
                                ))
                            ) : (
                                <Grid size={{xs:12}} container justifyContent="center" alignItems="center" className="mt-3">
                                    <span>No data</span>
                                </Grid>
                            )}
                            
                        </Grid>
                    </Grid>
                    </>)}
                </Grid>
            </div>
        </div>
    );
}

export default Attachments;
