import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./admin.css";
import "./usermember.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiService } from '../../services/apiService';
import Select from 'react-select';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
function UserMemberEdit() {
    const { id } = useParams(); // Extract ID from URL params
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [btnloading, setBtnLoading] = useState(false);
    // State for roles and form data
    const [rolesOptions, setRolesOptions] = useState([]);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        dob: null,
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: '',
        roles: [], 
        profile: null,
    });
    const [preview, setPreview] = useState(null);
    const [errors, setErrors] = useState({});
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [errorsmessage, setErrorsMessage] = useState('');

    // Fetch roles when the component mounts
    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await apiService.getRequest('/admin/roles-list');
                const options = response.data.map((role) => ({
                    value: role.id,  // Store role id instead of name
                    label: role.name,
                }));
                setRolesOptions(options);

            } catch (error) {
                console.error('Error fetching roles:', error);
            }
        };
        fetchRoles();
    }, []);

    // Fetch user data when the component mounts or the user ID changes
    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`/admin/users/${id}`);
                const user = response.data.user;
                setPreview(user.profile);
                setFormData({
                    first_name: user.first_name || '',
                    last_name: user.last_name || '',
                    dob: user.dob ? dayjs(user.dob) : null, // Ensure it's a dayjs object or null if empty
                    phone_number: user.phone_number || '',
                    email: user.email || '',
                    roles: user.roles.map(role => ({ value: role.id, label: role.display_name })) || [], // Pre-populate roles
                });
            } catch (error) {
                console.error("Error fetching user data", error);
            }finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [id]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData({ ...formData, profile: file });
            const reader = new FileReader();
            reader.onloadend = () => setPreview(reader.result);
            reader.readAsDataURL(file);
        }
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    
    const handleRoleChange = (selectedOptions) => {
        setFormData({ ...formData, roles: selectedOptions });
    };
    const validate = () => {
        const newErrors = {};
        if (!formData.first_name) newErrors.first_name = 'First name is required';
        if (!formData.last_name) newErrors.last_name = 'Last name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid';
        if (!formData.phone_number) newErrors.phone_number = 'Phone number is required';
        else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.phone_number)) newErrors.phone_number = 'Phone number must be in the format (123) 456-7890';
        if (!formData.dob) newErrors.dob = 'Date of birth is required';
        if (formData.password !== formData.password_confirmation) newErrors.password_confirmation = 'Passwords do not match';
        if (formData.roles.length === 0) newErrors.roles = 'At least one Group is required';

        const email = formData.email;
        const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailFormat.test(email)) {
            newErrors.email = 'Please enter a valid email address.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
        setBtnLoading(true);

        const passwordFormat = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\\|{};:/?.<>])[\w!@#$%^&*()\-_=+\\|{};:/?.<>]{8,}$/;
    
    // Check if the password matches the format
    if (formData.password && !passwordFormat.test(formData.password)) {
        setErrors(prevErrors => ({
            ...prevErrors,
            password: 'Password must be at least 8 characters long, include an uppercase letter, a number, and a special character.',
        }));
        setBtnLoading(false);
        return; // Exit the function if password is invalid
    }
        try {
            const dataToSubmit = {
                ...formData,
                roles: formData.roles.map(role => role.value),
            };
            
            const formDataToSend = new FormData();
            
            Object.entries(dataToSubmit).forEach(([key, value]) => {
                if (key === "roles" && Array.isArray(value)) {
                    value.forEach(roleId => formDataToSend.append("roles[]", roleId));
                } else {
                    formDataToSend.append(key, value);
                }
            });

            const response = await apiService.postRequest(`/admin/users/${id}`, formDataToSend, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            if (response.success){
                toast('User updated successfully!');
                navigate("/user/member");
            }else{
                setErrors(response.errors || "");
                setErrorsMessage(response.message || "");
            }
        } catch (error) {
            console.error("Error updating user data", error);
            setErrorsMessage("Failed to update user.");
        }finally {
            setBtnLoading(false);
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container justifyContent="center" className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>User</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/member")}>User</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/user/member")}>Sub Admin</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Edit Sub Admin</span>
                    </Grid>
                    <Grid size={{xs:12}} container className="editgrouphead my-4">
                        <h2>Edit Sub Admin</h2>
                    </Grid>
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                    <form className='row justify-content-center w-100' onSubmit={handleSubmit}>
                        <Grid size={{ sm: 11, xs: 12 }} container justifyContent="space-between" className="add_memberfields">
                            {/* Profile Picture Upload */}
                            <Grid size={{ sm: 12, xs: 12 }} container alignItems="center" className="profile_uploadlab mb-5">
                                <div className="profile-upload-wrapper">
                                    <label htmlFor="profile-upload-input" className="profile-upload-label">
                                        {preview ? (
                                            <img src={preview} alt="Profile Preview" className="profile-preview" />
                                        ) : (
                                            <div className="placeholder-icon">
                                                <img src={require('../../assets/cameraicon.svg').default} alt='Upload' />
                                            </div>
                                        )}
                                    </label>
                                    <input
                                        id="profile-upload-input"
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        style={{ display: 'none' }}
                                    />
                                </div>
                                <small>Upload Profile Picture</small>
                                <div className='w-100'>
                                    {errors.profile && <p style={{ color: "red" }}>{errors.profile}</p>}
                                </div>
                            </Grid>
                            {/* Role Selection */}
                            <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="mb-4">
                                <span>Group<small className='red_starimpo'>*</small></span>
                                <Select
                                    isMulti
                                    name="roles"
                                    value={formData.roles}
                                    onChange={handleRoleChange}
                                    options={rolesOptions}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    placeholder="Choose Group..."
                                />
                                {errors.roles && <p style={{ color: "red" }}>{errors.roles}</p>}
                            </Grid>
                            {/* Email */}
                            <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="memberfield_individual mb-4">
                                <span>Email Id<small className='red_starimpo'>*</small></span>
                                <input
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
                            </Grid>
                            {/* First Name */}
                            <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="memberfield_individual mb-4">
                                <span>First Name<small className='red_starimpo'>*</small></span>
                                <input
                                    type="text"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleInputChange}
                                    onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                />
                                {errors.first_name && <p style={{ color: "red" }}>{errors.first_name}</p>}
                            </Grid>
                            {/* Last Name */}
                            <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="memberfield_individual mb-4">
                                <span>Last Name<small className='red_starimpo'>*</small></span>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleInputChange}
                                    onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                />
                                {errors.last_name && <p style={{ color: "red" }}>{errors.last_name}</p>}
                            </Grid>
                            {/* Date of Birth */}
                            <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="memberfield_individual mb-4">
                                <span>Date of Birth<small className='red_starimpo'>*</small></span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="date_pickermux"
                                        value={formData.dob}
                                        onChange={(date) => setFormData({ ...formData, dob: date })}
                                        maxDate={dayjs().subtract(18, 'year').subtract(1, 'day')}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                </LocalizationProvider>
                                {errors.dob && <p style={{ color: "red" }}>{errors.dob}</p>}
                            </Grid>
                            {/* Phone Number */}
                            <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="memberfield_individual mb-4">
                                <span>Phone Number<small className='red_starimpo'>*</small></span>
                                <input
                                    type="text"
                                    name="phone_number"
                                    value={formData.phone_number}
                                    placeholder="***_***_****"
                                    maxLength="14"
                                    onChange={handleInputChange}
                                />
                                {errors.phone_number && <p style={{ color: "red" }}>{errors.phone_number}</p>}
                            </Grid>
                            {/* Password */}
                            <Grid size={{ xs: 12 }} container justifyContent="space-between">
                                <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="mb-4">
                                    <span>Password</span>
                                    <div className='d-flex input_witheye'>
                                        <input
                                            name="password"
                                            type={passwordVisible ? 'text' : 'password'}
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            placeholder="Abcd123#"
                                            style={{ width: '100%', paddingRight: '40px' }}
                                        />
                                        <button type="button" onClick={togglePasswordVisibility}>
                                            {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                        </button>
                                    </div>
                                    {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
                                </Grid>
                                {/* Confirm Password */}
                                <Grid size={{ sm: 5.5, xs: 12 }} container flexDirection="column" className="mb-4">
                                    <span>Confirm Password</span>
                                    <div className='d-flex input_witheye'>
                                        <input
                                            name="password_confirmation"
                                            value={formData.password_confirmation}
                                            onChange={handleInputChange}
                                            type={passwordVisible2 ? 'text' : 'password'}
                                            placeholder="Abcd123#"
                                            style={{ width: '100%', paddingRight: '40px' }}
                                        />
                                        <button type="button" onClick={togglePasswordVisibility2}>
                                            {passwordVisible2 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                        </button>
                                    </div>
                                    {errors.password_confirmation && <p style={{ color: "red" }}>{errors.password_confirmation}</p>}
                                </Grid>
                            </Grid>
                            {errorsmessage && <p style={{ color: "red", textAlign: "center" }}>{errorsmessage}</p>}
                            {/* Submit Button */}
                            <Grid size={{sm:12,xs:12}} container justifyContent="flex-end" className="gap-lg-5 gap-3 my-3">
                                <button className='border_btnpink' onClick={()=>navigate("/user/member")}>Cancel</button>
                                <button type="submit" className='bgpink_btnpink'>{btnloading ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                            </Grid>
                        </Grid>
                    </form>
                </>}
            </Grid>
        </div>
    </div>
  )
}

export default UserMemberEdit
