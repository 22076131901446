import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./catalogs.css";
import { faAngleRight, faFilePen, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
function MemberServices() {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const closeElement = () => {
    setIsVisible(true);
  };
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible === true ? 'hide_mobitr' : 'show_mobitr'}>
            <div className='klgcategory_main'>
                <div className='d-flex w-100 justify-content-end'>
                    <button className='d-md-none closecat_btn' onClick={closeElement}><FontAwesomeIcon icon={faXmark} /></button>
                </div>
                <div className='d-flex flex-column catalog_caregoryspans pe-3'>
                    <h4>Categories</h4>
                    <span onClick={() =>{navigate("/catalogs/jobseeker-services")}}>Jobseeker Services</span>
                    <span className='active_catalog' onClick={() =>{navigate("/catalogs/member-services")}}>Member Services</span>
                </div>
            </div>
        </div>
        <div className='content_container_articals'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Member Services</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Catalog <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Member Services</span>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            Category
                        </button>
                    </Grid>
                </Grid>
                <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4">
                    {/* <Grid size={{md:5,xs:12}} container flexDirection="column">
                        <div className='d-flex jobseekersearch my-3'>
                            <div className='col-2'>
                                <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                            </div>
                            <div className='col-7'>
                                <input type='text' placeholder='search'
                                    // value={searchQuery}
                                    // onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                            </div>
                            <div className='col-3'>
                                <button 
                                // onClick={handleSearch}
                                >Search</button>
                            </div>
                        </div>
                    </Grid> */}
                    <Grid size={{xs:12}} container className="mt-4 gap-md-4">
                        <Grid size={{md:3.8,xs:12}} container flexDirection="column" justifyContent="space-between" className="grid_requestcatelog mt-3">
                            <FontAwesomeIcon className='icons_fa' icon={faFilePen} />
                            <h6>Tekp Community Member</h6>
                            <span>Community members shape environments through connections and leadership.</span>
                            <small>View Details</small>
                        </Grid>
                        {/* <Grid size={{md:3.8,xs:12}} container flexDirection="column" justifyContent="space-between" className="grid_requestcatelog mt-3">
                            <FontAwesomeIcon className='icons_fa' icon={faFilePen} />
                            <h6>Marketing Hold Request</h6>
                            <span>To start your Jobseeker journey with us, submit the Jobseeker Registration </span>
                            <small>View Details</small>
                        </Grid>
                        <Grid size={{md:3.8,xs:12}} container flexDirection="column" justifyContent="space-between" className="grid_requestcatelog mt-3">
                            <FontAwesomeIcon className='icons_fa' icon={faFilePen} />
                            <h6>Report a Technical Issue</h6>
                            <span>To start your Jobseeker journey with us, submit the Jobseeker Registration </span>
                            <small>View Details</small>
                        </Grid>
                        <Grid size={{md:3.8,xs:12}} container flexDirection="column" justifyContent="space-between" className="grid_requestcatelog mt-3">
                            <FontAwesomeIcon className='icons_fa' icon={faFilePen} />
                            <h6>Edit Registration</h6>
                            <span>To start your Jobseeker journey with us, submit the Jobseeker Registration </span>
                            <small>View Details</small>
                        </Grid>
                        <Grid size={{md:3.8,xs:12}} container flexDirection="column" justifyContent="space-between" className="grid_requestcatelog mt-3">
                            <FontAwesomeIcon className='icons_fa' icon={faFilePen} />
                            <h6>Learn about BenchTrack Certifications</h6>
                            <span>To start your Jobseeker journey with us, submit the Jobseeker Registration </span>
                            <small>View Details</small>
                        </Grid>
                        <Grid size={{md:3.8,xs:12}} container flexDirection="column" justifyContent="space-between" className="grid_requestcatelog mt-3">
                            <FontAwesomeIcon className='icons_fa' icon={faFilePen} />
                            <h6>Edit Registration</h6>
                            <span>To start your Jobseeker journey with us, submit the Jobseeker Registration </span>
                            <small>View Details</small>
                        </Grid> */}

                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default MemberServices
