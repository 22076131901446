import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./knowledge.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { apiService } from '../../services/apiService';
function UnderApprovalShow() {
    const location = useLocation();
    const UserIdAuth = localStorage.getItem('userid');
    const UserRoles = localStorage.getItem('role');
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [submitloader, setSubmitloader] = useState("");
    const [catesNames,setCateNames] = useState('');
    const [articleDetail, setArticleDetail] = useState([]);
    const [hideunapprover,setHidesunapprove] = useState('');
    const [authIdcompare,setAuthId] = useState('');
    const permissionstest = JSON.parse(localStorage.getItem('permissions'));
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        comment: '',
        status: '',
    });
    const { id } = useParams();
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === "comment" && value) {
            setError("");
        }
    };
    useEffect(() => {
        const fetchPreviewData = async () => {
            try {
                const response = await apiService.getRequest(`knowledge-article/${id}`);
                if (response && response.success) {
                    setCateNames(response.data.subcategory);
                    setHidesunapprove(response.data.status);
                    setArticleDetail(response.data);
                    setAuthId(String(response.data.author.id));
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        };

        fetchPreviewData();
    }, [id]);

    const handleSubmit = async (e,status) => {
        e.preventDefault();
          setSubmitloader(status);
          setSubmitloader(status);
          
            if (status === "draft" && !formData.comment) {
                setError("Justification is required when saving as a draft.");
                setSubmitloader("");
                return;
            }
          try {
            const jsonParams = {
                ...formData,
                status,
            };
              const response = await apiService.putRequest(`knowledge-articles/${id}/status`, jsonParams);
              if (response && response.success === true) {
                if (status === "published"){
                    navigate(`/knowledge-article/${articleDetail.format_id}`)
                }else{
                    navigate("/knowledge/under-approval")
                }
              }
            } catch (error) {
                console.log('An unexpected error occurred.',error); 
            }finally {
                setSubmitloader("");
            }
        };
    useEffect(() => {
        const fetchPreviewData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`knowledge-articles/${id}/approvals-data`);
                if (response && response.success === true && response.data) {
                    setArticles(response.data);
                } else {
                    setArticles([]);
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
                setArticles([]);
            } finally {
                setLoading(false);
            }
        };
    
        fetchPreviewData();
    }, [id]);
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center" className="mario_fixers_article">
                    <Grid size={{sm:12}} container alignItems="center">
                        {articleDetail && articleDetail.status === "under_approval" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Under Approval</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "draft" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Edit Draft</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "published" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Published</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "retired" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Retired</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                    </Grid>
                </Grid>
                {loading ? (
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div>
                ) : (
                    <>
                <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                    <div className='knowledgetab_btnredirect'>
                        <button onClick={() =>{navigate(`/knowledge/edit-article/${id}`)}}>Article</button>
                        <button className={currentPath.startsWith("/knowledge/under-approval/show") ? "active_tabbtn" : ""} onClick={() =>{navigate(`/knowledge/under-approval/show/${id}`)}}>Approvals</button>
                        <button onClick={() =>{navigate(`/knowledge/under-approval/history/${id}`)}}>History</button>
                        <button onClick={() =>{navigate(`/knowledge/attachments/${id}`)}}>Attachments</button>
                        <button onClick={() =>{navigate(`/knowledge/discussions/${id}`)}}>Discussions</button>
                        <button onClick={() =>{navigate(`/knowledge/versions/${id}`)}}>Versions</button>
                    </div>
                </Grid>
                {articles && Object.keys(articles).length > 0 ? (
                    <Grid size={{xs:12}} container className="author_spans mt-4">
                        <Grid size={{md:6,xs:12}} container>
                            <Grid size={{md:6,xs:12}} container flexDirection="column" className="my-2">
                                <span>Author</span>
                                <h5>{articles.author?.name || 'N/A'}</h5>
                            </Grid>
                            <Grid size={{md:6,xs:12}} container flexDirection="column" className="my-2">
                                <span>Owned by Group</span>
                                <h5>{articles.owned_by_role || 'N/A'}</h5>
                            </Grid>
                            <Grid size={{md:6,xs:12}} container flexDirection="column" className="my-2">
                                <span>Approval Group</span>
                                <h5>{articles.approval_role || 'N/A'}</h5>
                            </Grid>
                            <Grid size={{md:6,xs:12}} container flexDirection="column" className="my-2">
                                <span>Approver</span>
                                <h5>{articles.approver || 'N/A'}</h5>
                            </Grid>
                        </Grid>
                        {authIdcompare === UserIdAuth && UserRoles !== "admin" ? "" : <>
                        {permissionstest && permissionstest.approve_knowledge_article === true && hideunapprover === "under_approval"  ? 
                            <form className='w-100' onSubmit={handleSubmit}>
                                <Grid size={{xs:12}} container className="my-3 approval_textarea">
                                    <label>Write a Justification</label>
                                    <textarea name="comment" onChange={handleInputChange} placeholder='Write your Justification'/>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </Grid>
                                <Grid size={{xs:12}} container className="my-3 gap-3">
                                    <button className='registersendbtn1' type="submit" onClick={(e) => handleSubmit(e,"published")}>{(submitloader === "published") ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Publish'}</button>
                                    <button className='registersendbtn2' type="submit" onClick={(e) => handleSubmit(e,"draft")}>{(submitloader === "draft") ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Back to Draft'}</button>
                                </Grid>
                            </form>
                        :""}
                        <Grid size={{xs:12}} container className="apporovehistoryscoll pe-md-3">
                            {articles.history && articles.history.map((reply,index) => (
                                <Grid size={{ sm: 12 }} key={index} container alignItems="center" justifyContent="space-between" className="mb-4 ps-5 approvalcomment">
                                    <small className='pink_small'>{reply.user_name}</small>
                                    <small className='datetime_small'>{reply.date}</small>
                                    <span>{reply.comment}</span>
                                </Grid>
                            ))}
                        </Grid>
                        </>}
                    </Grid>
                ) : (
                    <div className="w-100 d-flex justify-content-center mt-4">
                        <p>No data to display</p>
                    </div>
                )}
                </>
                )}
            </Grid>
        </div>
    </div>
  )
}

export default UnderApprovalShow
