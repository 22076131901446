import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./knowledge.css";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faUser } from '@fortawesome/free-regular-svg-icons';
import { apiService } from '../../services/apiService';
import { Modal } from 'react-bootstrap';
// import { diffWords } from "diff";
import { diffWordsWithSpace } from "diff";
import DOMPurify from "dompurify";
// import highlightDifferences from './highlightDifferences';
function VersionsArticle() {
    const location = useLocation();
    const currentPath = location.pathname;
    const navigate = useNavigate();
    const [catesNames,setCateNames] = useState('');
    const [articleDetail, setArticleDetail] = useState([]);
    const [verLists, setVersions] = useState([]);
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const { id } = useParams();
    const [previewData1, setPreviewData1] = useState([]);
    const [previewData2, setPreviewData2] = useState([]);
    const [docudatas, setDocumentdatas] = useState([]);
    const [veridshows1, setVerid1] = useState([]);
    const [veridshows2, setVerid2] = useState([]);
    const [firstSelectValue, setFirstSelectValue] = useState('');
    const [secondSelectValue, setSecondSelectValue] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const fetchPreviewData = async () => {
            try {
                const response = await apiService.getRequest(`knowledge-article/${id}`);
                if (response && response.success) {
                    setCateNames(response.data.subcategory);
                    setArticleDetail(response.data);
                    setDocumentdatas(response.data);
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        };

        fetchPreviewData();
    }, [id]);

    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedata, setPaginateData] = useState({});
    
    const fetchPreviewData = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const defaultParams = {...params };
            const queryString = new URLSearchParams(defaultParams).toString();
            const response = await apiService.getRequest(`articles/${id}/versions?${queryString}`);
            if (response && response.success === true) {
                setArticles(response.data);
                setVersions(response.versions_list);
                setPaginateData(response.meta);
            }else {
                setArticles([]);
            }
        } catch (error) {
            console.error('Error fetching article data:', error);
            setArticles([]);
        } finally {
            setLoading(false);
        }
    }, [id]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchPreviewData({ page: newPage });
    };
    useEffect(() => {
        fetchPreviewData();
    }, [fetchPreviewData]);

    const handleFirstSelectChange = (e) => {
        setFirstSelectValue(e.target.value);
    };

    const handleSecondSelectChange = (e) => {
        setSecondSelectValue(e.target.value);
    };

    const filteredVerListsForFirstSelect = verLists.filter(
        (verlistdata) => verlistdata.id !== parseInt(secondSelectValue)
    );

    const filteredVerListsForSecondSelect = verLists.filter(
        (verlistdata) => verlistdata.id !== parseInt(firstSelectValue)
    );

    const validateSelection = () => {
        if (!firstSelectValue || !secondSelectValue) {  
            setError('Both versions must be selected.');
            return false;
        }
        if (firstSelectValue === secondSelectValue) {
            setError('You cannot compare the same version.');
            return false;
        }
        setError('');
        return true;
    };
    const handleCompare = async () => {  
        if (!validateSelection()) return;

            const versionId1 = firstSelectValue;
            const versionId2 = secondSelectValue;

            try {
                // Sending the GET request to compare versions
                const response = await apiService.getRequest(`articles/${id}/compare-versions/${versionId1}/${versionId2}`);
                    if(response && response.success === true){
                        setVerid1(response.data.version1);   
                        setVerid2(response.data.version2);
                        setPreviewData1(Array.isArray(response.data.version1) ? response.data.version1 : [response.data.version1]);
                        setPreviewData2(Array.isArray(response.data.version2) ? response.data.version2 : [response.data.version2]);
                        setShow(true)
                    }
            } catch (error) {
                console.error('Error comparing versions:', error);
            }
    };  
    const highlightDifferences = (oldText, newText) => {
        const differences = diffWordsWithSpace(oldText || "", newText || "");
        return differences.map((part, index) => (
            <span className='first_child_changes'
            key={index}
            style={{
                backgroundColor: part.added ? "lightgreen" : part.removed ? "lightcoral" : "transparent",
                textDecoration: part.removed ? "line-through" : "none",
            }}
            dangerouslySetInnerHTML={{ __html: sanitizeAndDisplayHTML(part.value) }} // Pass `part.value` directly
        />
        ));
      };

    const handleClose = () => setShow(false);
    const sanitizeAndDisplayHTML = (html) => {
        return DOMPurify.sanitize(html); // Sanitize to prevent unsafe HTML content
      };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center" className="mario_fixers_article">
                    <Grid size={{sm:12}} container alignItems="center">
                        {articleDetail && articleDetail.status === "under_approval" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Under Approval</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "draft" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Edit Draft</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "published" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Published</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}
                        {articleDetail && articleDetail.status === "retired" ? (
                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                <h3>Retired</h3>
                                <span>{catesNames.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {catesNames.name}</span>
                            </Grid>
                        ):""}  
                    </Grid>
                </Grid>
                {loading ? (
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div>
                ) : (
                    <>
                <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                    <div className='knowledgetab_btnredirect'>
                        <button onClick={() =>{navigate(`/knowledge/edit-article/${id}`)}}>Article</button>
                        {articleDetail && articleDetail.status === "draft" ? "":
                        <button onClick={() =>{navigate(`/knowledge/under-approval/show/${id}`)}}>Approvals</button>}
                        <button onClick={() =>{navigate(`/knowledge/under-approval/history/${id}`)}}>History</button>
                        <button onClick={() =>{navigate(`/knowledge/attachments/${id}`)}}>Attachments</button>
                        <button onClick={() =>{navigate(`/knowledge/discussions/${id}`)}}>Discussions</button>
                        <button className={currentPath.startsWith("/knowledge/versions") ? "active_tabbtn" : ""} onClick={() =>{navigate(`/knowledge/versions/${id}`)}}>Versions</button>
                    </div>   
                </Grid>
                <Grid size={{xs:12}} container justifyContent="flex-end" className="compare_artibtns gap-3 mt-5">
                    <select value={firstSelectValue} onChange={handleFirstSelectChange}>
                        <option value="" disabled>Select Version</option>
                        {filteredVerListsForFirstSelect.map((verlistdata) => (
                            <option key={verlistdata.id} value={verlistdata.id}>
                                Version: {verlistdata.version}
                            </option>
                        ))}
                    </select>

                    <select value={secondSelectValue} onChange={handleSecondSelectChange}>
                        <option value="" disabled>Select Version</option>
                        {filteredVerListsForSecondSelect.map((verlistdata) => (
                            <option key={verlistdata.id} value={verlistdata.id}>
                                Version: {verlistdata.version}
                            </option>
                        ))}
                    </select>
                    <button onClick={handleCompare}>Compare</button>
                    <div className='w-100 text-end'>
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                    </div>
                </Grid>
                <div className='overtanblehistory w-100'>
                    <Grid size={{xs:12}} container alignContent="flex-start" className="mt-3 tablemin_height">
                        <Grid size={{xs:12}} container className="history_tableheads">
                            <Grid size={{xs:1.5}} container>
                                <h6>S.No</h6>
                            </Grid>
                            <Grid size={{xs:2}} container>
                                <h6>Versions</h6>
                            </Grid>
                            <Grid size={{xs:3.5}} container>
                                <h6>Author</h6>
                            </Grid>
                            <Grid size={{xs:3}} container>
                                <h6>Updated On</h6>
                            </Grid>
                            <Grid size={{xs:2}} container>
                                <h6>Status</h6>
                            </Grid>
                        </Grid>
                        
                                {articles && articles.length > 0 ? ( articles && articles.map((article,index) => (
                                    <Grid size={{xs:12}} key={index} alignItems="center" container className="history_tableans">
                                        <Grid size={{xs:1.5}} container>
                                            <span>{index+1}</span>
                                        </Grid>
                                        <Grid size={{xs:2}} container flexDirection="column">
                                            <span>Version: {article.version}</span>
                                        </Grid>
                                        <Grid size={{xs:3.5}} container flexDirection="column">
                                            <span>{article.published_by}</span>
                                        </Grid>
                                        <Grid size={{xs:3}} container>
                                            <span>{article.created_at}</span>
                                        </Grid>
                                        <Grid size={{xs:2}} container>
                                            <span>{article.status}</span>
                                        </Grid>
                                    </Grid>
                                ))
                                ) : (
                                    <div className="w-100 d-flex justify-content-center mt-4">
                                        <p>No data to display</p>
                                    </div>
                                )}
                    </Grid>
                </div>
                <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage - 1)} 
                        disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                        <button 
                            key={index} 
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage + 1)} 
                        disabled={currentPage === paginatedata.last_page}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </Grid>
                </>)}
            </Grid>
            <Modal show={show} fullscreen={true} centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Compare Article</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <Grid size={{xs:12}} container className="costom_modalcompare_overflowers">
                        <Grid size={{xs:12}} container className="costom_modalcompare">
                            <Grid size={{xs:6}} container>
                                <h5>Version {veridshows1.version}</h5>
                                <Grid size={{sm:12}} container alignContent="flex-start" className="main_jobseeker modal_comparecontent" style={{background:"#fff"}}>
                                {previewData1 && previewData1.map((article) => (
                                    <div key={article.id} className='w-100'>
                                        <Grid size={{sm:12}} container alignItems="center">
                                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                                <h3>Knowledge Article</h3>
                                                <span>{article.subcategory.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {article.subcategory.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {article.subcategory.name}</span>
                                            </Grid>
                                        </Grid>
                                        <Grid size={{sm:12}} container justifyContent="center" className="mt-4">
                                            <Grid size={{sm:12}} container justifyContent="center" className="knowledge_lister">
                                                <h4>{article.title}</h4>
                                                <Grid size={{xs:12}} container className="span_griders mb-4">
                                                    <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>{article.author.name}</span>
                                                    <span><FontAwesomeIcon icon={faEye} className='icon_kn'/>{article.view_count} Views</span>
                                                    <span><FontAwesomeIcon icon={faCalendar} className='icon_kn'/>{article.created_at}</span>
                                                </Grid>
                                                <Grid size={{xs:12}} container className="gap-3 mb-3">
                                                {Array.isArray(docudatas.documents) ? (
                                                    docudatas.documents.map(docum => (
                                                    <Grid key={docum.id} size={{lg:2.8,md:2.8,sm:3.8,xs:12}} alignItems="center" container className="documentcards">
                                                        {docum && (docum.extension ==="docx" || docum.extension ==="word") ?
                                                        <img src={require('../../assets/svg2.svg').default} alt={docum.name} /> : docum && docum.extension ==="pdf" ? <img src={require('../../assets/svg1.svg').default} alt={docum.name} /> : docum && docum.extension ==="csv" ? <img src={require('../../assets/svg4.svg').default} alt={docum.name} /> : <img src={require('../../assets/svg3.svg').default} alt={docum.name}/>
                                                        }
                                                        <a href={docum.file_path} target="_blank" rel="noopener noreferrer">{docum.document_name}</a>
                                                    </Grid>
                                                    ))
                                                ) : (
                                                    ""
                                                )}
                                                </Grid>
                                                <div className='w-100 caliberifontsset' dangerouslySetInnerHTML={{ __html: sanitizeAndDisplayHTML(article.content) }} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    ))
                                }
                                </Grid>
                            </Grid> 
                            <Grid size={{xs:6}} container>
                                <h5>Version {veridshows2.version}</h5>
                                <Grid size={{sm:12}} container alignContent="flex-start" className="main_jobseeker modal_comparecontent" style={{background:"#fff"}}>
                                {previewData2 && previewData2.map((article, index) => (
                                    <div key={article.id} className='w-100'>
                                        <Grid size={{sm:12}} container alignItems="center">
                                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                                                <h3>Knowledge Article</h3>
                                                <span>{article.subcategory.knowledge_base} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {article.subcategory.category_name} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {article.subcategory.name}</span>
                                            </Grid>
                                        </Grid> 
                                        <Grid size={{sm:12}} container justifyContent="center" className="mt-4">
                                            <Grid size={{sm:12}} container justifyContent="center" className="knowledge_lister">
                                                {/* <h4 className={!titlecolchange ? "back_changecols" : ""}>{article.title}</h4> */}
                                                {/* <h4>{highlightDifferences(previewData1[index]?.title, article.title)}</h4> */}
                                                <h4 dangerouslySetInnerHTML={{__html: highlightDifferences(previewData1[index]?.title, article.title),}}></h4>
                                                <Grid size={{xs:12}} container className="span_griders mb-4">
                                                    <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>{article.author.name}</span>
                                                    <span><FontAwesomeIcon icon={faEye} className='icon_kn'/>{article.view_count} Views</span>
                                                    <span><FontAwesomeIcon icon={faCalendar} className='icon_kn'/>{article.created_at}</span>
                                                </Grid>
                                                <Grid size={{xs:12}} container className="gap-3 mb-3">
                                                {Array.isArray(docudatas.documents) ? (
                                                    docudatas.documents.map(docum => (
                                                    <Grid key={docum.id} size={{lg:2.8,md:2.8,sm:3.8,xs:12}} alignItems="center" container className="documentcards">
                                                        {docum && (docum.extension ==="docx" || docum.extension ==="word") ?
                                                        <img src={require('../../assets/svg2.svg').default} alt={docum.name} /> : docum && docum.extension ==="pdf" ? <img src={require('../../assets/svg1.svg').default} alt={docum.name} /> : docum && docum.extension ==="csv" ? <img src={require('../../assets/svg4.svg').default} alt={docum.name} /> : <img src={require('../../assets/svg3.svg').default} alt={docum.name}/>
                                                        }
                                                        <a href={docum.file_path} target="_blank" rel="noopener noreferrer">{docum.document_name}</a>
                                                    </Grid>  
                                                    )) 
                                                ) : (
                                                    ""
                                                )} 
                                                </Grid>
                                                <div className='w-100 caliberifontsset'>
                                                    {highlightDifferences(previewData1[index]?.content, article.content)}
                                                </div>
                                                {/* <div className='w-100 caliberifontsset' dangerouslySetInnerHTML={{__html: highlightDifferences(previewData1[index]?.content, article.content),}}></div> */}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    ))
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Modal.Body>
            </Modal>
        </div>
    </div>
  )
}

export default VersionsArticle
