import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { faAngleRight, faHandshakeSimpleSlash } from '@fortawesome/free-solid-svg-icons';
import "./knowledge.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import { faCalendar, faEye, faHandshake, faUser } from '@fortawesome/free-regular-svg-icons';
import { Rating } from '@mui/material';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
import KnowledgeCategory from '../../components/common/KnowledgeCategory';
import { ResizableBox } from 'react-resizable';

function KnowledgeArticleView() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [previewData, setPreviewData] = useState([]);
    const { slug } = useParams();
    const [idforslugs, setIdforslugs] = useState([]);
    const [isHelpful, setIsHelpful] = useState(null);
    const [ratingValue, setRatingValue] = useState(0);
    const [comment, setComment] = useState('');
    const [replyComments, setReplyComments] = useState({});
    const [activeCommentId, setActiveCommentId] = useState(null); // Track the comment ID with an open reply form
    const [errors, setErrors] = useState({});
    const [docudatas, setDocumentdatas] = useState([]);
    const [subcatsdatas, setSubcatsdatas] = useState([]);

    const fetchPreviewData = useCallback(async () => {
        try {
            const response = await apiService.getRequest(`knowledge-articles/${slug}`);
            if (response && response.success === true) {
                setPreviewData(Array.isArray(response.data) ? response.data : [response.data]);
                setIdforslugs(response.data.id);
                setSubcatsdatas(response.data.subcategory);
                setRatingValue(response.data.user_rating);
                setIsHelpful(response.data.is_helpful);
                setDocumentdatas(response.data);
            }
        } catch (error) {
            console.error('Error fetching group data:', error);
        } finally {
            setLoading(false);
        }
    }, [slug]); // Dependency on slug

    // Call fetchPreviewData on mount and whenever slug changes
    useEffect(() => {
        fetchPreviewData();
    }, [fetchPreviewData]);

    const handleReplyChange = (e, commentId) => {
        const { value } = e.target; // Get the current input value
        console.log('Updating reply for comment ID:', commentId, 'to:', value); // Debugging output

        // Update the state to reflect the current textarea input
        setReplyComments((prevReplies) => ({
            ...prevReplies,
            [commentId]: value, // Set the value for the specific comment
        }));
    };

    const handleReplyClick = useCallback((commentId) => {
        setActiveCommentId(activeCommentId === commentId ? null : commentId);
    }, [activeCommentId]);

    const handleSubmitreply = useCallback(async (e, parentId) => {
        e.preventDefault(); // Prevent default form submission

        const replyText = replyComments[parentId]; // Get the current reply text
        if (!replyText) {
            console.log("Reply text is empty."); // Debugging output if empty
            return; // Prevent submission if empty
        }

        try {
            const jsonParams = {
                comment: replyText,
                parent_id: parentId,
            };
            const response = await apiService.postRequest(`knowledge-articles/${idforslugs}/comments`, jsonParams);
            if (response && response.success === true) {
                toast("Comment Submitted");
                setReplyComments((prev) => ({
                    ...prev,
                    [parentId]: ''
                }));
                setActiveCommentId(null);
                fetchPreviewData();
            }
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    }, [replyComments, idforslugs, fetchPreviewData]);


    const handleSubmit = async (e) => {
        e.preventDefault(); 
        const newErrors = {};
        if (comment.trim() === '') {
        newErrors.comment = "Please enter a comment.";
        }

        // If there are errors, update the errors state and do not submit
        if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
        }

        try{
            const jsonParams = {
                "comment": comment,
            }
            const response = await apiService.postRequest(`knowledge-articles/${idforslugs}/comments`,jsonParams);
            if(response && response.success === true){
                toast("Comment Submitted");
                setComment("");
                fetchPreviewData();
            }
            }catch (error) {
                console.error('Error fetching data:', error);
        }
    };


      const handleRatingChange = async (event, newValue) => {
        setRatingValue(newValue);
        try {
            const jsonParams = {
                rating: newValue,
            };
            const response = await apiService.postRequest(`knowledge-articles/${idforslugs}/ratings`, jsonParams);

            if (response && response.success === true) {
                toast("Rating Updated");
                fetchPreviewData();
            }
        } catch (error) {
            console.error('Error updating rating:', error);
        }
    };

    const handleClick = async (value) => { 
        setIsHelpful(value);
        try {
            const jsonParams = {
                "is_helpful": value,
            };
            const response = await apiService.postRequest(`knowledge-articles/${idforslugs}/ratings`, jsonParams);

            if (response && response.success === true) {
                toast("Helpfull updated");
                fetchPreviewData();
            }
        } catch (error) {
            console.error('Error updating rating:', error);
        }
    };

    // Trigger the Click function whenever isHelpful changes
    useEffect(() => {
        if (isHelpful !== null) {
            
        }
    }, [isHelpful]);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const closeElement = () => {
    setIsVisible(true);
  };
    
    const sanitizeAndDisplayHTML = (html) => {
        const sanitizedHTML = html
        return sanitizedHTML;
    };
  return (
    <div className='container-fluid px-0'>
        <div className="resizable-container d-flex">
            <div className="d-md-block d-none">
                <ResizableBox
                className="knowledge-category"
                width={400}
                height={Infinity}
                minConstraints={[400, Infinity]}
                maxConstraints={[window.innerWidth - 200, Infinity]}
                axis="x"
                resizeHandles={['e']}
                >
                <div className="knowledge-category-content">
                    <KnowledgeCategory onClose={closeElement}  
                    selectedKbaseId={subcatsdatas && subcatsdatas.base_id}
                    selectedCategory={subcatsdatas && subcatsdatas.category_id}
                    selectedSubCategory={subcatsdatas && subcatsdatas.subcategory_id}/>
                </div>
                </ResizableBox>
                </div>
                <div className="d-md-none d-block">
                <div className={isVisible !== true ? 'hide_mobitr' : 'show_mobitr'}>
                    <KnowledgeCategory onClose={closeElement}  
                    selectedKbaseId={subcatsdatas && subcatsdatas.base_id}
                    selectedCategory={subcatsdatas && subcatsdatas.category_id}
                    selectedSubCategory={subcatsdatas && subcatsdatas.subcategory_id}/>
                </div>
            </div>
            <div className='content_container_articals_drag' style={{ flexGrow: 1 }}>
                {loading ? 
                <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
                    <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                </div> :<>
                {previewData && previewData.map((article) => (<div key={article.id} className='w-100'>
                <Grid size={{sm:12}} container className="main_jobseeker" style={{background:"#fff"}}>
                    <Grid size={{sm:12}} container alignItems="center">
                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                            <h3>Knowledge Article</h3>
                            <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> 
                            <b onClick={()=>navigate("/knowledge")}>Knowledge Article</b><FontAwesomeIcon className='angleright' icon={faAngleRight} /> 
                            <b onClick={()=>{navigate('/knowledge'); localStorage.setItem('knowledgeKbaseBackId', String(article.base.id))}}>{article.base.name}</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> 
                            <b onClick={()=>{navigate('/knowledge'); localStorage.setItem('knowledgeCatBackId', String(article.subcategory.category_id));localStorage.setItem('knowledgeKbaseBackId', String(article.base.id))}}>{article.subcategory.category_name}</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> 
                            <b onClick={()=>{navigate('/knowledge'); localStorage.setItem('knowledgeBackId', String(article.subcategory.id));}}>{article.subcategory.name}</b></span>
                            <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                                Category
                            </button>
                        </Grid>
                    </Grid>
                    <Grid size={{sm:12}} container justifyContent="center" className="mt-4">
                        <Grid size={{sm:12}} container justifyContent="center" className="knowledge_lister">
                            <span className='formatid_articleshow'>{article.format_id}</span>
                            <h4>{article.title}</h4>
                            <Grid size={{xs:12}} container className="span_griders mb-4">
                                <span><FontAwesomeIcon icon={faUser} className='icon_kn'/><small>Authored by :</small>{article.author.name}</span>
                                <span><FontAwesomeIcon icon={faEye} className='icon_kn'/>{article.view_count} Views</span>
                                <span><FontAwesomeIcon icon={faHandshake} className='icon_kn'/>{article.helpful_true_count} Helpful</span>
                                <span><FontAwesomeIcon icon={faHandshakeSimpleSlash} className='icon_kn'/>{article.helpful_false_count} UnHelpful</span>
                                <span><FontAwesomeIcon icon={faCalendar} className='icon_kn'/>{article.created_at}</span>
                                <Rating className='rating_spanhiders' disabled value={JSON.parse(article.overall_rating)} />
                            </Grid>
                            <Grid size={{xs:12}} container className="gap-3 mb-3">
                                {Array.isArray(docudatas.documents) ? (
                                    docudatas.documents.map(docum => (
                                    <Grid key={docum.id} size={{lg:2.8,md:2.8,sm:3.8,xs:12}} alignItems="center" container className="documentcards">
                                        {docum && (docum.extension ==="docx" || docum.extension ==="word") ?
                                        <img src={require('../../assets/svg2.svg').default} alt={docum.name} /> : docum && docum.extension ==="pdf" ? <img src={require('../../assets/svg1.svg').default} alt={docum.name} /> : docum && docum.extension ==="csv" ? <img src={require('../../assets/svg4.svg').default} alt={docum.name} /> : <img src={require('../../assets/svg3.svg').default} alt={docum.name}/>
                                        }
                                        <a href={docum.file_path} target="_blank" rel="noopener noreferrer">{docum.document_name}</a>
                                    </Grid>
                                    ))
                                ) : (
                                    ""
                                )}
                            </Grid>
                            <div className='w-100 caliberifontsset' dangerouslySetInnerHTML={{ __html: sanitizeAndDisplayHTML(article.content) }} />
                        </Grid>
                    </Grid>
                        <Grid size={{xs:12}} container justifyContent="center" className="mt-4 top_downborders">
                            <Grid container size={{sm:6,xs:12}} alignItems="center" className="my-md-0 my-2">
                            <span className='raters_helpspan'>Helpful?</span>
                            <button 
                                type="button" 
                                className={isHelpful === true ? "yes_nohelper_selector" : 'yes_nohelper'}
                                onClick={() => handleClick(true)}
                            >
                                Yes
                            </button>
                            <button 
                                type="button" 
                                className={isHelpful === false ? "yes_nohelper_selector" : 'yes_nohelper'}
                                onClick={() => handleClick(false)}
                            >
                                No
                            </button>
                            </Grid>
                            <Grid container size={{sm:6,xs:12}} alignItems="center" alignContent="center" className="my-md-0 my-2 justify-content-md-end">
                            <span className='raters_helpspan'>Rate this article</span>
                            <Rating 
                                className='rating_spanhiders' 
                                value={ratingValue} 
                                onChange={handleRatingChange}
                            />
                            </Grid>
                        </Grid>
                        <form className='w-100' onSubmit={handleSubmit}>
                            <Grid size={{sm:12}} container justifyContent="flex-end" className="mt-4">
                                <textarea 
                                className='comment_textarea' 
                                placeholder="Post a comment...." 
                                value={comment} 
                                onChange={(e) => setComment(e.target.value)} 
                                />
                                <div className='w-100'>
                                    {errors.comment && <p style={{color:"red"}}>{errors.comment}</p>}
                                </div>
                                <button type="submit" className='submit_btn'>Submit</button>
                            </Grid>
                        </form>
                    <Grid size={{sm:12}} container justifyContent="center" className="mt-4 previous_comments">
                        {article && article.comments.map((comment) => (
                            <Grid size={{ sm: 12 }} key={comment.id} container alignItems="center" justifyContent="space-between" className="mb-4 reply_comment_btn">
                            <small className='pink_small'>{comment.user}</small>
                            <small className='datetime_small'>{comment.created_at}</small>
                            <span>{comment.comment}</span>
                            <button className='reply_btntext' onClick={() => handleReplyClick(comment.id)}>
                                {activeCommentId === comment.id ? "Cancel" : "Reply"}
                            </button>
                    
                            {activeCommentId === comment.id && (
                                <div className='d-flex flex-column w-100 mb-2'>
                                    <textarea
                                        value={replyComments[comment.id] || ''} // Ensure correct value binding
                                        onChange={(e) => handleReplyChange(e, comment.id)} // Update on change
                                        placeholder="Write your reply..."
                                    />
                                    <button style={{opacity:!replyComments[comment.id]?.trim() ? "0.8":"1"}} disabled={!replyComments[comment.id]?.trim()}  onClick={(e) => handleSubmitreply(e, comment.id)}>Send Reply</button>
                                </div>
                            )}
                            {comment.replies && comment.replies.map((reply) => (
                                <Grid size={{ sm: 12 }} key={reply.id} container alignItems="center" justifyContent="space-between" className="mb-4 ps-5 reply_comment_btn">
                                    <small className='pink_small'>{reply.user} <small>(@{reply.parentUser})</small></small>
                                    <small className='datetime_small'>{reply.created_at}</small>
                                    <span>{reply.comment}</span>
                                    <button className='reply_btntext' onClick={() => handleReplyClick(reply.id)}>
                                        {activeCommentId === reply.id ? "Cancel" : "Reply"}
                                    </button>
                            
                                    {activeCommentId === reply.id && (
                                        <div className='d-flex flex-column w-100'>
                                            <textarea
                                                value={replyComments[reply.id] || ''} // Ensure correct value binding
                                                onChange={(e) => handleReplyChange(e, reply.id)} // Update on change
                                                placeholder="Write your reply..."
                                            />
                                            <button style={{opacity:!replyComments[reply.id]?.trim() ? "0.8":"1"}} disabled={!replyComments[reply.id]?.trim()} onClick={(e) => handleSubmitreply(e, reply.id)}>Send Reply</button>
                                        </div>
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                        ))}
                    </Grid>
                </Grid>
            </div>
        
        ))
        }
    </>}
            </div>
        </div>
    </div>
  )
}

export default KnowledgeArticleView
