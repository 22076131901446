import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, matchPath } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Loginselect from '../pages/Login/Loginselect';
import Homepage from '../pages/homepage/Homepage';
import RegisterDetail1 from '../pages/Login/RegisterDetail1';
import RegisterDetail2 from '../pages/Login/RegisterDetail2';
import RegisterDetail3 from '../pages/Login/RegisterDetail3';
import RegisterDetail4 from '../pages/Login/RegisterDetail4';
import TermsandCondition from '../pages/Terms/TermsandCondition';
import UserJobSeekers from '../pages/admin/UserJobSeekers';
import Jobseekerdetails from '../pages/admin/Jobseekerdetails';
import Dashboard from '../pages/user/Dashboard';
import ForgetPassword from '../pages/Login/ForgetPassword';
import ResetPassword from '../pages/Login/ResetPassword';
import { useState } from 'react';
import UserGroup from '../pages/admin/UserGroup';
import UserGroupEdit from '../pages/admin/UserGroupEdit';
import UserMember from '../pages/admin/UserMember';
import UserGroupAdd from '../pages/admin/UserGroupAdd';
import UserMemberAdd from '../pages/admin/UserMemberAdd';
import UserMemberEdit from '../pages/admin/UserMemberEdit';
import JobseekerProfile from '../pages/admin/JobseekerProfile';
import RegisterIds from '../pages/Login/RegisterIds';
import KnowledgeArticle from '../pages/Knowledge/KnowledgeArticle';
import KnowledgeArticleView from '../pages/Knowledge/KnowledgeArticleView';
import KnowledgeBase from '../pages/Knowledge/KnowledgeBase';
import KnowledgeCategory from '../pages/Knowledge/KnowledgeCategory';
import KnowledgeSubCategory from '../pages/Knowledge/KnowledgeSubCategory';
import Draft from '../pages/Knowledge/Draft';
import DraftAdd from '../pages/Knowledge/DraftAdd';
import KnowledgeArticlePreview from '../pages/Knowledge/KnowledgeArticlePreview';
import DraftEdit from '../pages/Knowledge/DraftEdit';
import UnderApproval from '../pages/Knowledge/UnderApproval';
import UnderApprovalShow from '../pages/Knowledge/UnderApprovalShow';
import UnderApprovalHistory from '../pages/Knowledge/UnderApprovalHistory';
import Published from '../pages/Knowledge/Published';
import Retired from '../pages/Knowledge/Retired';
import Allheader from '../components/common/Allheader';
import Catalogs from '../pages/Jobseekers/Catalog/Catalogs';
import EditRequest from '../pages/Jobseekers/Catalog/EditRequest';
import MarketHold from '../pages/Jobseekers/Catalog/MarketHold';
import Certifications from '../pages/Jobseekers/Catalog/Certifications';
import ReportTechIssue from '../pages/Jobseekers/Catalog/ReportTechIssue';
import Feedback from '../pages/Jobseekers/Catalog/Feedback';
import Attachments from '../pages/Knowledge/Attachments';
import Discursion from '../pages/Knowledge/Discursion';
import Notifications from '../components/common/Notifications';
import VersionsArticle from '../pages/Knowledge/VersionsArticle';
import MyRequest from '../pages/Jobseekers/Tasks/MyRequest';
import MyTask from '../pages/Jobseekers/Tasks/MyTask';
import ProfileCorrection from '../pages/Jobseekers/Tasks/ProfileCorrection';
import I94Validation from '../pages/Jobseekers/Tasks/I94Validation/I94Validation';
import Assignments from '../pages/Jobseekers/Tasks/I94Validation/Assignments';
import TaskDisccuss from '../pages/Jobseekers/Tasks/I94Validation/TaskDisccuss';
import TaskKnowledge from '../pages/Jobseekers/Tasks/I94Validation/TaskKnowledge';
import TaskHistory from '../pages/Jobseekers/Tasks/I94Validation/TaskHistory';
import VerifyMember from '../pages/Jobseekers/Tasks/VerifyMember/VerifyMember';
import MemberConfirmation from '../pages/Jobseekers/Tasks/VerifyMember/MemberConfirmation';
import ReferralInvestigation from '../pages/Jobseekers/Tasks/VerifyMember/ReferralInvestigation';
import JobseekersClassification from '../pages/Jobseekers/Tasks/JobseekersClassification/JobseekersClassification';
import EngagementOverview from '../pages/Jobseekers/Tasks/EngagementOverview/EngagementOverview';
import SetupInterview from '../pages/Jobseekers/Tasks/SetupInterview/SetupInterview';
import InterviewEvaluation from '../pages/Jobseekers/Tasks/SetupInterview/InterviewEvaluation';
import TermsofServices from '../pages/Terms/TermsofServices';
import PrivacyPolicy from '../pages/Terms/PrivacyPolicy';
import ApplicationFinalReview from '../pages/Jobseekers/Tasks/ApplicationFinalReview/ApplicationFinalReview';
import CommunicationEligibility from '../pages/Jobseekers/Tasks/CommunicationEligibility/CommunicationEligibility';
import PastPaymentHealth from '../pages/Jobseekers/Tasks/PastPaymentHealth/PastPaymentHealth';
import AdmissionFeesPayment from '../pages/Jobseekers/Tasks/PastPaymentHealth/AdmissionFeesPayment';
import ImmigrationHistory from '../pages/Jobseekers/Tasks/ImmigrationHistory/ImmigrationHistory';
import ImmigrationReview from '../pages/Jobseekers/Tasks/ImmigrationHistory/ImmigrationReview';
import JobPostLinkedin from '../pages/Jobseekers/Tasks/PublishJobPost/JobPostLinkedin';
import ApplyforJob from '../pages/Jobseekers/Tasks/PublishJobPost/ApplyforJob';
import GroupTask from '../pages/Jobseekers/Tasks/GroupTask';
import PlacementAgreements from '../pages/Jobseekers/Tasks/ApplicationFinalReview/PlacementAgreements';
import ValidateCats from '../components/common/ValidateCats';
import AllTaskList from '../pages/Jobseekers/Tasks/AllTaskList';
import MyCases from '../pages/Jobseekers/Tasks/MyCases';
import TechReportView from '../pages/Jobseekers/Tasks/TechReportView';
import MemberServices from '../pages/Jobseekers/Catalog/MemberServices';

const AppRoutes = () => {
  const [formData, setFormData] = useState({});
  const [role, setRole] = useState(localStorage.getItem('role'));
  const permissionstest = JSON.parse(localStorage.getItem('permissions'));

  const handleFormDataChange = (stepData) => {
    setFormData(prevData => ({ ...prevData, ...stepData }));
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setRole(localStorage.getItem('role'));
    };

    // Add event listener to detect localStorage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  function OnboardSiders() {
    const location = useLocation();
  
    // Define the paths where the component should be visible
    const allowedPaths = [
      "/task/i94-validation/:userid",
      "/task/verify-member/:userid", 
      "/task/member-confirmation/:userid", 
      "/task/referral-investigation/:userid", 
      "/task/engagement-overview/:userid", 
      "/task/jobseekers-classification/:userid", 
      "/task/setup-interview/:userid", 
      "/task/servicenow-interview-evaluation/:userid", 
      "/task/past-payment-health/:userid", 
      "/task/application-final-review/:userid", 
      "/task/placement-agreements/:userid", 
      "/task/communication-eligibility/:userid", 
      "/task/admission-fees-payment/:userid",
      "/task/profile-correction/:requestId",
      ];
  
    // Check if the current path is in the allowedPaths array
    const isVisible = allowedPaths.some((path) => matchPath({ path, end: true }, location.pathname));
  
  const [isVisiblemob, setIsVisible] = useState(true);
      const toggleVisibility = () => {
          setIsVisible(!isVisiblemob);
      };
      const closeElement = () => {
          setIsVisible(true);
      };
    return (
      <>
        {isVisible && (
          <div className={isVisiblemob ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement} />
          </div>
        )}
        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
            List
        </button>
      </>
    );
  }

  
  const handleClose = () => console.log("ValidateCats closed");
  const AuthenticatedRoutes = () => (
    <>
      <Allheader />
      <OnboardSiders />
      <Routes>
        
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/notification" element={<Notifications />} />
        <Route path="/knowledge" element={<KnowledgeArticle />} />
        <Route path="/knowledge-article/:slug" element={<KnowledgeArticleView />} />

        <Route path="/catalogs/jobseeker-services" element={<Catalogs />} />
        <Route path="/catalogs/member-services" element={<MemberServices />} />
        <Route path="/catalogs/editrequest" element={<EditRequest />} />
        <Route path="/market-hold" element={<MarketHold />} />
        <Route path="/certifications" element={<Certifications />} />
        <Route path="/catalogs/report-tech-issue" element={<ReportTechIssue />} />
        {permissionstest && permissionstest.manage_knowledge_article === true ?<>
        <Route path="/task/view-tech-issue/:requestId" element={<TechReportView />} />
        <Route path="/anonymous-feedback" element={<Feedback />} />
        <Route path="/task/my-request" element={<MyRequest />} />
        <Route path="/task/my-task" element={<MyTask/>} />
        <Route path="/task/group-task" element={<GroupTask/>} />
        <Route path="/task/my-cases" element={<MyCases/>} />
        <Route path="/task/all-task/:optionalParam?" element={<AllTaskList/>} />
        <Route path="/task/assignments/:taskId" element={<Assignments/>} />
        <Route path="/task/discussions/:taskId" element={<TaskDisccuss/>} />
        <Route path="/task/knowledge/:taskId" element={<TaskKnowledge/>} />
        <Route path="/task/history/:taskId" element={<TaskHistory/>} />
        <Route path="/task/i94-validation/:userid" element={<I94Validation onClose={handleClose}/>} />
        <Route path="/task/verify-member/:userid" element={<VerifyMember/>} />
        <Route path="/task/member-confirmation/:userid" element={<MemberConfirmation/>} />
        <Route path="/task/referral-investigation/:userid" element={<ReferralInvestigation/>} />
        <Route path="/task/engagement-overview/:userid" element={<EngagementOverview/>} />
        <Route path="/task/jobseekers-classification/:userid" element={<JobseekersClassification/>} />
        <Route path="/task/setup-interview/:userid" element={<SetupInterview/>} />
        <Route path="/task/servicenow-interview-evaluation/:userid" element={<InterviewEvaluation/>} />
        <Route path="/task/past-payment-health/:userid" element={<PastPaymentHealth/>} />
        <Route path="/task/application-final-review/:userid" element={<ApplicationFinalReview/>} />
        <Route path="/task/placement-agreements/:userid" element={<PlacementAgreements/>} />
        <Route path="/task/communication-eligibility/:userid" element={<CommunicationEligibility/>} />
        <Route path="/task/admission-fees-payment/:userid" element={<AdmissionFeesPayment/>} />
        <Route path="/task/immigration-history" element={<ImmigrationHistory/>} />
        <Route path="/task/immigration-review" element={<ImmigrationReview/>} />
        <Route path="/task/job-post-linkedin" element={<JobPostLinkedin/>} />
        <Route path="/task/apply-job" element={<ApplyforJob/>} />
        <Route path="/task/profile-correction/:requestId" element={<ProfileCorrection/>} />
        </>:""}
        {role && (role === "admin" || role === "sub_admin") && (
          <>
          {permissionstest && (permissionstest.manage_jobseeker_reader === true || permissionstest.manage_subadmin_reader === true || permissionstest.manage_group_reader === true )?<>
            <Route path="/user/group" element={<UserGroup />} />
            <Route path="/user/group-add" element={<UserGroupAdd />} />
            <Route path="/user/member" element={<UserMember />} />
            <Route path="/user/member-add" element={<UserMemberAdd />} />
            <Route path="/user/member-edit/:id" element={<UserMemberEdit />} />
            <Route path="/user/group-edit/:id" element={<UserGroupEdit />} />
            <Route path="/user/jobseekers" element={<UserJobSeekers />} />
            </>:""}
            <Route path="/user/jobseeker-details/:id" element={<Jobseekerdetails />} />
            <Route path="/user/jobseeker-profile/:id" element={<JobseekerProfile />} />
            {permissionstest && permissionstest.manage_knowledge_article === true ?<>
            <Route path="/knowledge/draft" element={<Draft />} />
            <Route path="/knowledge/attachments/:id" element={<Attachments />} />
            <Route path="/knowledge/under-approval" element={<UnderApproval />} />
            <Route path="/knowledge/published" element={<Published />} />
            <Route path="/knowledge/retired" element={<Retired />} />
            <Route path="/knowledge/under-approval/show/:id" element={<UnderApprovalShow />} />
            <Route path="/knowledge/under-approval/history/:id" element={<UnderApprovalHistory />} />
            <Route path="/knowledge/versions/:id" element={<VersionsArticle />} />
            <Route path="/knowledge/discussions/:id" element={<Discursion />} />
            <Route path="/knowledge/add-draft" element={<DraftAdd />} />
            <Route path="/knowledge/edit-article/:id" element={<DraftEdit />} />
            <Route path="/knowledge/preview/:id" element={<KnowledgeArticlePreview />} />
            {role && role === "admin" && <>
            <Route path="/knowledge/root" element={<KnowledgeBase />} />
            <Route path="/knowledge/category" element={<KnowledgeCategory />} />
            <Route path="/knowledge/sub-category" element={<KnowledgeSubCategory />} />
            </>}
            </>:""}
          </>
        )}
        {/* Redirect to login if route is not found */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );

  return (
    <div className='container-fluid px-0'>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/loginselect" element={<Loginselect />} />
        <Route path="/forgotpassword" element={<ForgetPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/termsandcondition" element={<TermsandCondition />} />
        <Route path="/termsofservice" element={<TermsofServices />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/registration" element={<RegisterIds />} />
        <Route path="/registration/personal-questions" element={<RegisterDetail1 formData={formData} onFormDataChange={handleFormDataChange} />} />
        <Route path="/registration/immigration-questions" element={<RegisterDetail2 formData={formData} onFormDataChange={handleFormDataChange} />} />
        <Route path="/registration/emergency-contact" element={<RegisterDetail3 formData={formData} onFormDataChange={handleFormDataChange} />} />
        <Route path="/registration/referral-information" element={<RegisterDetail4 formData={formData} onFormDataChange={handleFormDataChange} />} />
        {/* Private Routes */}
        <Route path="/*" element={<AuthenticatedRoutes />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
