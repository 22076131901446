import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Select from 'react-select';
// import { useNavigate } from 'react-router-dom';\
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { apiService } from '../../services/apiService';
import { faCircleDown } from '@fortawesome/free-regular-svg-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
const profileImage = require('../../assets/defaultprofile.svg').default;

const DataTables = ({ jobseekers, onSuspend }) => {
    // const navigate = useNavigate();
    const permissionstest = JSON.parse(localStorage.getItem('permissions'));
    const [loading, setLoading] = useState(false);
    const [profiles, setProfile] = useState([]);
    const [profiles2, setProfile2] = useState([]);
    const [profiles3, setProfile3] = useState([]);
    const [profiles4, setProfile4] = useState([]);

    const [isGridVisible, setGridVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [uniqueCityStatuses, setCitizenshipStatuses] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [show, setShow] = useState(false);
    const [showrefere, setShowRefere] = useState(false);
    const handleClose = () => setShow(false);
    const handleCloserefere = () => setShowRefere(false);
    // const handleShow = () => setShow(true);
    const fetchUserData = async (userId) => {
        setLoading(true);
        try {
            const response = await apiService.getRequest(`/admin/jobseeker/${userId}`);
            if (response.success === true){
                setProfile(response.data);
                if(response.data.immigration_data.length !== 0){
                    setProfile2(response.data.immigration_data);
                }
                if(response.data.emergency_contact.length !== 0){
                    setProfile3(response.data.emergency_contact);
                }
                if(response.data.referral_info.length !== 0){
                    setProfile4(response.data.referral_info);
                }
            }else{
                
            }
        } catch (error) {
            console.error("Error fetching user data", error);
        }finally {
            setLoading(false);
        }
    };
    const handleShow = (userId) => {
        setShow(true); // Set the ID
        fetchUserData(userId); // Fetch user data
    };

    
    const toggleGrid = () => {
        setGridVisible((prev) => !prev);
    };

    const [filters, setFilters] = useState([{ operator: 'And', column: 'userName', operatorType: 'contains', value: '' }]);


    const combineOptions = [
        { value: 'And', label: 'And' },
        { value: 'Or', label: 'Or' },
    ];
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.postRequest('generalsettings-data');
            if (response.success) {
                setCitizenshipStatuses(response.citizenship_statuses);
            }
        };
        fetchData();
    }, []);
    const rows = jobseekers.map((datas) => ({
        id: datas.id,
        formatid: datas.format_id,
        userName: datas.name,
        userImage: datas.profile || profileImage,
        // status: datas.status_details.label,
        members: 4,
        cityStatus:datas.citizenship_status,
        city:datas.city,
        state:datas.state_name,
        refereName:datas.referrer_name,
        contactPhone: datas.phone_number,
        contactEmail: datas.email,
        date: datas.created_at,
        suspended: datas.suspended,
        zipcodes: datas.zipcode,
        address: datas.personal_info.address,
        date_birth: datas.personal_info.dob,
        awaitingeadcard: datas.immigration_data && datas.immigration_data.awaiting_ead_card,
        eadcardapplieddate: datas.immigration_data && datas.immigration_data.ead_card_applied_date,
        legallyauthorizedtowork: datas.immigration_data && datas.immigration_data.legally_authorized_to_work,
        workauthorizationexpiry: datas.immigration_data && datas.immigration_data.work_authorization_expiry,
        renewauthorizationwork: datas.immigration_data && datas.immigration_data.renew_authorization_work,
        ssnissueyear: datas.immigration_data && datas.immigration_data.ssn_issue_year,
        ecfirstname: datas.emergency_contact && datas.emergency_contact.first_name,
        eclastname: datas.emergency_contact && datas.emergency_contact.last_name,
        ecphone: datas.emergency_contact && datas.emergency_contact.phone_number,
        ecrelation: datas.emergency_contact && datas.emergency_contact.relation_type,
        riphone: datas.referral_info && datas.referral_info.referral_phone_number,
        rirelation: datas.referral_info && datas.referral_info.referrer_relationship,
        // colors: datas.status_details.fg_color,
        // bgcolors: datas.status_details.bg_color,
    }));

    const handleFilterChange = (index, field, value) => {
        const newFilters = [...filters];
        // Reset value if changing the column type
        if (field === 'column') {
            newFilters[index].value = newFilters[index].column === 'date' ? [null, null] : '';
        }
        newFilters[index][field] = value;
        setFilters(newFilters);
    };

    const addFilter = () => {
        setFilters([...filters, { operator: 'And', column: 'userName', operatorType: 'contains', value: '' }]);
    };

    const removeFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };

    const operatorOptions = [
        { value: 'contains', label: 'contains' },
        { value: 'doesNotContain', label: 'does not contain' },
        { value: 'startsWith', label: 'starts with' },
        { value: 'endsWith', label: 'ends with' },
        { value: 'is', label: 'is' },
        { value: 'isNot', label: 'is not' },
        { value: 'isEmpty', label: 'is empty' },
        { value: 'isNotEmpty', label: 'is not empty' },
    ];

    const getOperatorOptions = (column) => {
        if (column === 'cityStatus') {
            return [
                { value: 'contains', label: 'contains' },
                { value: 'doesNotContain', label: 'does not contain' },
                { value: 'startsWith', label: 'starts with' },
                { value: 'endsWith', label: 'ends with' },
                { value: 'is', label: 'is' },
                { value: 'isNot', label: 'is not' },
                { value: 'isOneOf', label: 'is one of' },
                { value: 'isNotOneOf', label: 'is not one of' },
                { value: 'isEmpty', label: 'is empty' },
                { value: 'isNotEmpty', label: 'is not empty' },
            ];
        }else if (column === 'eadcardapplieddate') {
            return [
                { value: 'on', label: 'On' },
                { value: 'notOn', label: 'Not On' },
                { value: 'before', label: 'Before' },
                { value: 'atOrBefore', label: 'At Or Before' },
                { value: 'after', label: 'After' },
                { value: 'atOrAfter', label: 'At Or After' },
                { value: 'between', label: 'Between' },
                { value: 'isEmpty', label: 'is empty' },
                { value: 'isNotEmpty', label: 'is not empty' },
            ];
        }
        return operatorOptions; // Default options
    };
    
    const filteredRows = rows.filter((row) =>
        filters.reduce((acc, filter, index) => {
            const rowValue = row[filter.column] ? row[filter.column].toString() : '';
            let matches = false;
    
            // if (filter.column === 'date_birth' && Array.isArray(filter.value) && filter.value.length === 2) {
            //     const [startDate, endDate] = filter.value;
            //     const rowDate = new Date(row.date);
            //     matches = (!startDate || rowDate >= new Date(startDate)) && (!endDate || rowDate <= new Date(endDate));
            // }else 
            if (filter.column === 'ssnissueyear' && Array.isArray(filter.value) && filter.value.length === 2) {
                const [startDate, endDate] = filter.value;
                const rowDate = new Date(row.date);
                
                // Extract the year from the row date
                const rowYear = rowDate.getFullYear();
                const startYear = startDate ? new Date(startDate).getFullYear() : null;
                const endYear = endDate ? new Date(endDate).getFullYear() : null;
                // Perform comparison based on the year only
                matches = (
                    (!startYear || rowYear >= startYear) && 
                    (!endYear || rowYear <= endYear)
                );
            }else if (filter.column === 'workauthorizationexpiry') {
                const rowDate = new Date(row.createdOn);
                
                switch (filter.operatorType) {
                    case 'on':
                        matches = rowDate.toISOString().split('T')[0] === new Date(filter.value).toISOString().split('T')[0];
                        break;
                    case 'notOn':
                        matches = rowDate.toISOString().split('T')[0] !== new Date(filter.value).toISOString().split('T')[0];
                        break;
                    case 'before':
                        matches = rowDate < new Date(filter.value);
                        break;
                    case 'atOrBefore':
                        matches = rowDate <= new Date(filter.value);
                        break;
                    case 'after':
                        matches = rowDate > new Date(filter.value);
                        break;
                    case 'atOrAfter':
                        matches = rowDate >= new Date(filter.value);
                        break;
                    case 'between':
                        if (Array.isArray(filter.value) && filter.value.length === 2) {
                            const [startDate, endDate] = filter.value.map(date => new Date(date));
                            matches = (!startDate || rowDate >= startDate) && (!endDate || rowDate <= endDate);
                        } else {
                            matches = false;
                        }
                        break;
                    case 'isEmpty':
                        matches = !row.createdOn;
                        break;
                    case 'isNotEmpty':
                        matches = !!row.createdOn;
                        break;
                    default:
                        matches = false;
                }
            } 
            else if (filter.column === 'eadcardapplieddate') {
                const rowDate = row.eadcardapplieddate ? new Date(row.eadcardapplieddate) : null;
                const filterDate = filter.value ? new Date(filter.value) : null;
                switch (filter.operatorType) {
                    case 'on':
                        matches = rowDate && filterDate && rowDate.toDateString() === filterDate.toDateString();
                        break;
                    case 'notOn':
                        matches = rowDate && filterDate && rowDate.toDateString() !== filterDate.toDateString();
                        break;
                    case 'before':
                        matches = rowDate && filterDate && rowDate < filterDate;
                        break;
                    case 'atOrBefore':
                        matches = rowDate && filterDate && rowDate <= filterDate;
                        break;
                    case 'after':
                        matches = rowDate && filterDate && rowDate > filterDate;
                        break;
                    case 'atOrAfter':
                        matches = rowDate && filterDate && rowDate >= filterDate;
                        break;
                    case 'between':
                        if (Array.isArray(filter.value) && filter.value.length === 2) {
                            const [startDate, endDate] = filter.value.map(date => (date ? new Date(date) : null));
                            matches = rowDate && 
                                    startDate && endDate && 
                                    rowDate >= startDate && rowDate <= endDate;
                        } else {
                            matches = false;
                        }
                        break;
                    case 'isEmpty':
                        matches = !row.eadcardapplieddate;
                        break;
                    case 'isNotEmpty':
                        matches = !!row.eadcardapplieddate;
                        break;
                    default:
                        matches = false;
                }
            }
            else if (filter.column === 'date_birth') {
                const rowDate = row.date_birth ? new Date(row.date_birth) : null;
                const filterDate = filter.value ? new Date(filter.value) : null;
                switch (filter.operatorType) {
                    case 'on':
                        matches = rowDate && filterDate && rowDate.toDateString() === filterDate.toDateString();
                        break;
                    case 'notOn':
                        matches = rowDate && filterDate && rowDate.toDateString() !== filterDate.toDateString();
                        break;
                    case 'before':
                        matches = rowDate && filterDate && rowDate < filterDate;
                        break;
                    case 'atOrBefore':
                        matches = rowDate && filterDate && rowDate <= filterDate;
                        break;
                    case 'after':
                        matches = rowDate && filterDate && rowDate > filterDate;
                        break;
                    case 'atOrAfter':
                        matches = rowDate && filterDate && rowDate >= filterDate;
                        break;
                    case 'between':
                        if (Array.isArray(filter.value) && filter.value.length === 2) {
                            const [startDate, endDate] = filter.value.map(date => (date ? new Date(date) : null));
                            matches = rowDate && 
                                    startDate && endDate && 
                                    rowDate >= startDate && rowDate <= endDate;
                        } else {
                            matches = false;
                        }
                        break;
                    case 'isEmpty':
                        matches = !row.date_birth;
                        break;
                    case 'isNotEmpty':
                        matches = !!row.date_birth;
                        break;
                    default:
                        matches = false;
                }
            }
            else if (filter.column === 'workauthorizationexpiry') {
                const rowDate = row.workauthorizationexpiry ? new Date(row.workauthorizationexpiry) : null;
                const filterDate = filter.value ? new Date(filter.value) : null;
                switch (filter.operatorType) {
                    case 'on':
                        matches = rowDate && filterDate && rowDate.toDateString() === filterDate.toDateString();
                        break;
                    case 'notOn':
                        matches = rowDate && filterDate && rowDate.toDateString() !== filterDate.toDateString();
                        break;
                    case 'before':
                        matches = rowDate && filterDate && rowDate < filterDate;
                        break;
                    case 'atOrBefore':
                        matches = rowDate && filterDate && rowDate <= filterDate;
                        break;
                    case 'after':
                        matches = rowDate && filterDate && rowDate > filterDate;
                        break;
                    case 'atOrAfter':
                        matches = rowDate && filterDate && rowDate >= filterDate;
                        break;
                    case 'between':
                        if (Array.isArray(filter.value) && filter.value.length === 2) {
                            const [startDate, endDate] = filter.value.map(date => (date ? new Date(date) : null));
                            matches = rowDate && 
                                    startDate && endDate && 
                                    rowDate >= startDate && rowDate <= endDate;
                        } else {
                            matches = false;
                        }
                        break;
                    case 'isEmpty':
                        matches = !row.workauthorizationexpiry;
                        break;
                    case 'isNotEmpty':
                        matches = !!row.workauthorizationexpiry;
                        break;
                    default:
                        matches = false;
                }
            }
            else if (filter.column === 'cityStatus') {
                switch (filter.operatorType) {
                    case 'is':
                        matches = rowValue === filter.value;
                        break;
                    case 'isNot':
                        matches = rowValue !== filter.value;
                        break;
                    case 'contains':
                        matches = rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'doesNotContain':
                        matches = !rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'startsWith':
                        matches = rowValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case 'endsWith':
                        matches = rowValue.toLowerCase().endsWith(filter.value.toLowerCase());
                        break;
                    case 'isEmpty':
                        matches = rowValue === '';
                        break;
                    case 'isNotEmpty':
                        matches = rowValue !== '';
                        break;
                    case 'isOneOf': // Check if value is one of the provided options
                        matches = filter.value.includes(rowValue);
                        console.log(filter.value)
                        break;
                    case 'isNotOneOf': // Check if value is not one of the provided options
                        matches = !filter.value.includes(rowValue);
                        break;
                    default:
                        matches = false;
                }
            } else {
                // General filter logic for other columns
                switch (filter.operatorType) {
                    case 'contains':
                        matches = rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'doesNotContain':
                        matches = !rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'startsWith':
                        matches = rowValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case 'endsWith':
                        matches = rowValue.toLowerCase().endsWith(filter.value.toLowerCase());
                    break;
                    case 'is':
                        matches = rowValue.toLowerCase() === filter.value.toLowerCase();
                        break;
                    case 'isNot':
                        matches = rowValue.toLowerCase() !== filter.value.toLowerCase();
                        break;
                    case 'isEmpty':
                        matches = rowValue === '';
                        break;
                    case 'isNotEmpty':
                        matches = rowValue !== '';
                        break;
                    default:
                        matches = false;
                }
            }
    
            return index === 0 ? matches : (filter.operator === 'And' ? acc && matches : acc || matches);
        }, true)
    );
    const paginatedRows = filteredRows.slice(currentPage * pageSize, currentPage * pageSize + pageSize);

    const downloadCSV = () => {
        const csvData = filteredRows.map(row => ({
            'Jobseeker No': row.formatid,
            'User Name': row.userName,
            'Citizenship Status': row.cityStatus,
            'Email': row.contactEmail,
            'Phone Number': row.contactPhone,
            'State': row.state,
            'City': row.city,
            'Referrer Name': row.refereName,
        }));
        const csv = Papa.unparse(csvData); // Parse the data into CSV
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'filtered_jobseekers.csv'); // Trigger file download
    };

    const columns = [
        {
            field: 'formatid',
            headerName: 'Jobseeker No',
            width: 250,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span className='cursetespoint' style={{color:"#C42B7D"}} onClick={() => handleShow(params.row.id)}>{params.row.formatid}</span>
                </div>
            ),
            filterable: true,
        },
        {
            field: 'userName',
            headerName: 'User',
            width: 250,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <Avatar src={params.row.userImage} alt={params.row.userName} />
                    <span className='ms-3'>{params.row.userName}</span>
                </div>
            ),
            filterable: true
        },
        {
            field: 'cityStatus',
            headerName: 'Citizenship Status',
            width: 200,
            filterable: true,
        },
        {
            field: 'date_birth',
            headerName: 'Date of Birth',
            width: 200,
            filterable: true,
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     width: 150,
        //     filterable: true,
        //     renderCell: (params) => (
        //         <div className='d-flex align-items-center h-100'>
        //             <span style={{ color: params.row.colors, background: params.row.bgcolors }} className='bg_dynaspangroupjsa'>{params.value}</span>
        //         </div>
        //     )
        // },
        // {
        //     field: 'members',
        //     headerName: 'Members',
        //     width: 220,
        //     filterable: true,
        //     renderCell: () => (
        //         <div className='d-flex align-items-center gap-2 h-100'>
        //             <Avatar src={profileImage} alt="Member" />
        //             <Avatar src={profileImage} alt="Member" />
        //             <Avatar src={profileImage} alt="Member" />
        //             <Avatar src={profileImage} alt="Member" />
        //         </div>
        //     )
        // },
        {
            field: 'contactEmail',
            headerName: 'Email',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box className="linklistview h-100 d-flex align-items-center">
                    <Typography><span><a href={`mailto:${params.row.contactEmail}`} target="_blank" rel="noopener noreferrer">{params.row.contactEmail}</a></span></Typography>
                </Box>
            )
        },
        {
            field: 'contactPhone',
            headerName: 'Phone',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box className="linklistview h-100 d-flex align-items-center">
                    <Typography><span><a href={`tel:${params.row.contactPhone}`} target="_blank" rel="noopener noreferrer">{params.row.contactPhone}</a></span></Typography>
                </Box>
            )
        },
        {
            field: 'state',
            headerName: 'State',
            width: 200,
            filterable: true,
        },
        {
            field: 'city',
            headerName: 'City',
            width: 200,
            filterable: true,
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 200,
            filterable: true,
        },
        {
            field: 'zipcodes',
            headerName: 'Zipcode',
            width: 200,
            filterable: true,
        },
        {
            field: 'awaitingeadcard',
            headerName: 'Awaiting EAD Card',
            width: 200,
            filterable: true,
        },
        {
            field: 'eadcardapplieddate',
            headerName: 'EAD card applied date',
            width: 200,
            filterable: true,
        },
        {
            field: 'legallyauthorizedtowork',
            headerName: 'Legally authorized to work',
            width: 200,
            filterable: true,
        },
        {
            field: 'workauthorizationexpiry',
            headerName: 'Work authorization Expiry',
            width: 200,
            filterable: true,
        },
        {
            field: 'renewauthorizationwork',
            headerName: 'Renew authorization work',
            width: 200,
            filterable: true,
        },
        {
            field: 'ssnissueyear',
            headerName: 'SSN issue year',
            width: 200,
            filterable: true,
        },
        {
            field: 'ecfirstname',
            headerName: 'Emergency contact First Name',
            width: 200,
            filterable: true,
        },
        {
            field: 'eclastname',
            headerName: 'Emergency contact Last Name',
            width: 200,
            filterable: true,
        },
        {
            field: 'ecphone',
            headerName: 'Emergency contact Phone Number',
            width: 200,
            filterable: true,
        },
        {
            field: 'ecrelation',
            headerName: 'Emergency contact Relation Type',
            width: 200,
            filterable: true,
        },
        {
            field: 'refereName',
            headerName: 'Referrer Name',
            width: 200,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span className='cursetespoint' onClick={() => setShowRefere(true)}>{params.row.refereName}</span>
                </div>
            ),
            filterable: true,
        },
        {
            field: 'riphone',
            headerName: 'Referrer Phone Number',
            width: 200,
            filterable: true,
        },
        {
            field: 'rirelation',
            headerName: 'Referrer Relation Type',
            width: 200,
            filterable: true,
        },
        
        // {
        //     field: 'date',
        //     headerName: 'Date',
        //     width: 200,
        //     filterable: true,
        // },
        ...(permissionstest && permissionstest.manage_jobseeker_writer
            ? [
                {
                    field: 'action',
                    headerName: 'Action',
                    width: 200,
                    renderCell: (params) => (
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <button
                                className='suspent_btn'
                                onClick={() => onSuspend(params.row.id, params.row.suspended === 1)}
                            >
                                {params.row.suspended === 0 ? "Suspend" : "UnSuspend"}
                            </button>
                        </div>
                    ),
                },
            ]
            : []
        ),
    ];

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({
        zipcodes: false,
        address: false,
        awaitingeadcard: false,
        eadcardapplieddate: true,
        legallyauthorizedtowork: false,
        workauthorizationexpiry: false,
        renewauthorizationwork: false,
        ssnissueyear: false,
        ecfirstname: false,
        eclastname: false,
        ecphone: false,
        ecrelation: false,
        riphone: false,
        rirelation: false,
    });
    const columnOptions = [
        { label: "User Name", value: "userName" },
        { label: "Jobseeker No", value: "formatid" },
        { label: "Citizenship Status", value: "cityStatus" },
        { label: "Date of Birth", value: "date_birth" },
        { label: "Contact Email", value: "contactEmail" },
        { label: "Phone Number", value: "contactPhone" },
        { label: "State", value: "state" },
        { label: "City", value: "city" },
        { label: "Referrer Name", value: "refereName" },
        { label: "Zipcode", value: "zipcodes" },
        { label: "Address", value: "address" },
        { label: "Awaiting EAD card", value: "awaitingeadcard" },
        { label: "EAD card applied date", value: "eadcardapplieddate" },
        { label: "Legally authorized to work", value: "legallyauthorizedtowork" },
        { label: "Work authorization expiry", value: "workauthorizationexpiry" },
        { label: "Renew authorization work", value: "renewauthorizationwork" },
        { label: "SSN issue year", value: "ssnissueyear" },
        { label: "Emergency Contact First name", value: "ecfirstname" },
        { label: "Emergency Contact Last name", value: "eclastname" },
        { label: "Emergency Contact Phone number", value: "ecphone" },
        { label: "Emergency Contact Relation type", value: "ecrelation" },
        { label: "Referrer Phone number", value: "riphone" },
        { label: "Referrer Relation type", value: "rirelation" },
    ];
    return (
        <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
            {isGridVisible && (
            <Box sx={{ mb: 2 }} className="my-4">
                {filters.map((filter, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'end', mb: 1 }}>
                        {index > 0 && (
                            <select
                                label="Combine"
                                value={filter.operator}
                                onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
                                sx={{ mr: 1, minWidth: 80 }}
                            >
                                {combineOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        )}
                        <Grid container flexDirection="column">
                            <label>Column</label>
                            <Select
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                options={columnOptions}
                                value={columnOptions.find(option => option.value === filter.column)}
                                onChange={(selectedOption) => handleFilterChange(index, 'column', selectedOption ? selectedOption.value : '')}
                                isSearchable
                                placeholder="Select Data to filter"
                                styles={{
                                    container: (provided) => ({ ...provided, minWidth: 120, marginRight: '8px' }),
                                }}
                            />
                        </Grid>
                        <Grid container flexDirection="column">
                            <label>Operator</label>
                            <Select
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                options={getOperatorOptions(filter.column).map(option => ({
                                    label: option.label,
                                    value: option.value
                                }))}
                                value={getOperatorOptions(filter.column).find(option => option.value === filter.operatorType)}
                                onChange={(selectedOption) => handleFilterChange(index, "operatorType", selectedOption ? selectedOption.value : "")}
                                isSearchable
                                placeholder="Select Operator"
                                styles={{ container: (provided) => ({ ...provided, minWidth: 120 }) }}
                            />
                        </Grid>
                        <Grid container flexDirection="column" className="mx-2">
                            {filter.operatorType === "isNotEmpty" || filter.operatorType === "isEmpty" ? "" :<>
                                {filter.operatorType !== "between" &&
                                <label>Value</label>}
                            {filter.column === 'workauthorizationexpiry' || filter.column === 'date_birth' || filter.column === 'eadcardapplieddate' ? (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className='filterrages'
                                            label={filter.operatorType === "between" ? "Start Date":"" }
                                            value={filter.value[0] || null}
                                            onChange={(newValue) => handleFilterChange(index, 'value', [newValue, filter.value[1]])}
                                        />
                                        {filter.operatorType === "between" && 
                                        <DatePicker
                                            label="End Date"
                                            className='filterrages'
                                            value={filter.value[1] || null}
                                            onChange={(newValue) => handleFilterChange(index, 'value', [filter.value[0], newValue])}
                                        />}
                                    </LocalizationProvider>
                                </Box>
                            ): filter.column === 'ssnissueyear' ?(
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            views={["year"]}
                                            className='filterrages'
                                            label="Start Date"
                                            value={filter.value[0] || null}
                                            onChange={(newValue) => handleFilterChange(index, 'value', [newValue, filter.value[1]])}
                                        />
                                        <DatePicker
                                            views={["year"]}
                                            label="End Date"
                                            className='filterrages'
                                            value={filter.value[1] || null}
                                            onChange={(newValue) => handleFilterChange(index, 'value', [filter.value[0], newValue])}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            ) : filter.column === 'cityStatus' && !(filter.operatorType === "isNotOneOf" || filter.operatorType === "isOneOf")  ? (
                                <select
                                    label="Value"
                                    value={filter.value}
                                    onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                    sx={{ mr: 1, minWidth: 120 }}
                                >
                                    <option value="" disabled>Select Citizenship Status</option>
                                    {uniqueCityStatuses.map((status) => (
                                    <option key={status.id} value={status.status}>
                                        {status.status}
                                    </option>
                                    ))}
                                </select>
                            ) : filter.column === 'cityStatus' && (filter.operatorType === "isNotOneOf" || filter.operatorType === "isOneOf")  ? (
                                <Select
                                    isMulti
                                    className="basic-single select_withsearch"
                                    classNamePrefix="select"
                                    options={uniqueCityStatuses.map(status => ({
                                        label: status.status,
                                        value: status.status
                                    }))}
                                    value={Array.isArray(filter.value) ? filter.value.map(val => ({ label: val, value: val })) : []}
                                    onChange={(selectedOptions) => 
                                        handleFilterChange(index, 'value', selectedOptions.map(option => option.value))
                                    }
                                    placeholder="Select Citizenship Status"
                                    styles={{ container: (provided) => ({ ...provided, minWidth: 120, marginRight: '8px' }) }}
                                />
                            ) : (
                                <input
                                    className='value_inputfilter'
                                    placeholder='Value'
                                    label="Value"
                                    value={filter.value}
                                    onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                />
                            )}</>}
                        </Grid>
                        <button className='filterremovebtn' onClick={() => removeFilter(index)}>Remove</button>
                    </Box>
                ))}
                <button className='filteraddbtn' onClick={addFilter}>Add Filter</button>
            </Box>)}
            <Grid size={{xs:12}} container justifyContent="space-between">
                <button className='show_filterbtn' onClick={toggleGrid}>
                    {isGridVisible ?<> Hide Filter <FontAwesomeIcon icon={faFilterCircleXmark} /> </> :<> Show Filter <FontAwesomeIcon icon={faFilter} /> </>}
                </button>
                <button className='download_csvbtndatatable' onClick={downloadCSV}>Download CSV<FontAwesomeIcon className='ps-2' icon={faCircleDown} /></button>
            </Grid>
            <DataGrid
                rows={paginatedRows}
                columns={columns}
                pageSize={pageSize}
                disableSelectionOnClick
                components={{ Toolbar: GridToolbar }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
            />
            <div className='d-flex flex-column my-3 pagination_datagrid'>
                <div className='d-flex justify-content-end align-items-center'>
                    <label>No. of Rows</label>
                    <select
                        label="Rows per page"
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                        sx={{ mr: 2 }}
                    >
                        {[5, 10, 20, 50].map((size) => (
                            <option key={size} value={size}>{size}</option>
                        ))}
                    </select>
                </div>

                <div className="d-flex justify-content-center my-3">
                    <button
                        className='nexter_btns'
                        variant="contained"
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                        disabled={currentPage === 0}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    {/* Page number buttons */}
                    <div className="d-flex align-items-center mx-2">
                        {Array.from({ length: Math.ceil(filteredRows.length / pageSize) }, (_, index) => (
                            <button
                                key={index}
                                className={`page-btn ${currentPage === index ? 'active' : ''}`}
                                onClick={() => setCurrentPage(index)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>

                    <button
                        className='nexter_btns'
                        variant="contained"
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredRows.length / pageSize) - 1))}
                        disabled={currentPage >= Math.ceil(filteredRows.length / pageSize) - 1}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </div>

                <Offcanvas show={show} onHide={handleClose} placement='end' className="ofcanvas_widthsetters">
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Grid size={{lg:12}} container alignItems="center" justifyContent="center">
                            <Grid size={{md:12,xs:12}} container alignItems="flex-start" justifyContent="space-between" flexDirection="row" className="jobseekerhead">
                                <h3>Jobseekers Profile</h3>
                            </Grid>
                            {loading ? 
                            <div className='d-flex justify-content-center w-100'>
                                <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                            </div> :
                                <Grid size={{md:11,xs:12}} container justifyContent="space-between" className="jobseekers_profilealls my-4">
                                    <Grid size={{xs:12}} container>
                                        <h4>Personal Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>First Name</span>
                                        <p>{profiles.first_name}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Last Name</span>
                                        <p>{profiles.last_name}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Email Id</span>
                                        <p>{profiles.email}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Date of Birth</span>
                                        <p>{profiles.dob}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Gender</span>
                                        <p className='capssets'>{profiles.gender}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Address</span>
                                        <p className='capssets'>{profiles.address}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>City</span>
                                        <p className='capssets'>{profiles.city}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>State</span>
                                        <p className='capssets'>{profiles.state}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Zip Code</span>
                                        <p>{profiles.zipcode}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Phone Number</span>
                                        <p>{profiles.phone_number}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Why do you want to enroll with us?</span>
                                        <p className='capssets'>{profiles.why_enroll}</p>
                                    </Grid>
                                    {profiles2 && profiles2.length === 0 ? 
                                    "":<>
                                    <Grid size={{xs:12}} container>
                                        <h4>Immigration Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Citizenship Status</span>
                                        <p className='capssets'>{profiles2.citizenship_status}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Are you waiting for your EAD Card to Arrive?</span>
                                        <p className='capssets'>{profiles2.awaiting_ead_card || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>When did you apply for your EAD Card?</span>
                                        <p className='capssets'>{profiles2.ead_card_applied_date || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Explain your citizenship status</span>
                                        <p className='capssets'>{profiles2.citizenship_explanation}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Are you legally authorized to work in United States ?</span>
                                        <p className='capssets'>{profiles2.legally_authorized_to_work}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>When will your work authorization expire ?</span>
                                        <p className='capssets'>{profiles2.work_authorization_expiry || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Which year was your SSN Issued ?</span>
                                        <p className='capssets'>{profiles2.ssn_issue_year || "No data"}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Most Recent I94 Copy</span>
                                        <a href={`${profiles2.i94_copy}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>I hereby authorize agents of TekPossible Consulting to access and verify the authenticity of my uploaded I-94 document through the official I-94 website (https://i94.cbp.dhs.gov/I94). I understand that this verification will be conducted in accordance with applicable laws and regulations.</span>
                                        <p>Yes</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Proof of Work Authorization</span>
                                        <a href={`${profiles2.proof_of_work_authorization}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Upload Profile Picture</span>
                                        <a href={`${profiles2.profile}`} target="_blank" rel="noopener noreferrer">View</a>
                                    </Grid>
                                    </>}
                                    {profiles3 && profiles3.length === 0 ?
                                    "":<>
                                    <Grid size={{sm:12,xs:12}} container>
                                        <h4>Emergency Contact</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>First Name</span>
                                        <p className='capssets'>{profiles3.first_name}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Last Name</span>
                                        <p className='capssets'>{profiles3.last_name}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Phone Number</span>
                                        <p className='capssets'>{profiles3.phone_number}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Relationship</span>
                                        <p className='capssets'>{profiles3.relation_type}</p>
                                    </Grid>
                                    </>}
                                    {profiles4 && profiles4.length === 0 ? 
                                    "":<>
                                    <Grid size={{sm:12,xs:12}} container>
                                        <h4>Referral Information</h4>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Referrer Phone Number</span>
                                        <p className='capssets'>{profiles4.referral_phone_number}</p>
                                    </Grid>
                                    <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>Referrer Name</span>
                                        <p className='capssets'>{profiles4.referrer_name}</p>
                                    </Grid>
                                    <Grid size={{sm:12,xs:12}} container flexDirection="column" className="seekprof_borders">
                                        <span>How do you know the referrer ?</span>
                                        <p className='capssets'>{profiles4.referrer_relationship}</p>
                                    </Grid>
                                    </>}
                                </Grid>
                            }
                        </Grid>
                    </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={showrefere} onHide={handleCloserefere} placement='end' className="ofcanvas_widthsetters">
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Grid size={{lg:12}} container alignItems="center" justifyContent="center">
                            <Grid size={{md:12,xs:12}} container alignItems="flex-start" justifyContent="space-between" flexDirection="row" className="jobseekerhead">
                                <h3>Referrer Detail</h3>
                            </Grid>
                            <Grid size={{md:11,xs:12}} container justifyContent="space-between" className="jobseekers_profilealls my-4">
                                {/* <Grid size={{xs:12}} container>
                                    <h4>Personal Information</h4>
                                </Grid> */}
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Member ID</span>
                                    <p>#MEMBER0000</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Full Name</span>
                                    <p>Example Name</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Phone Number</span>
                                    <p>(123) 456-7890</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Email Id</span>
                                    <p>example@gamil.com</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>State</span>
                                    <p className='capssets'>Arkansas</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>City</span>
                                    <p className='capssets'>Alexander</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Level</span>
                                    <p>0</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Total Referrals</span>
                                    <p>0</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Reffered By</span>
                                    <p>Name</p>
                                </Grid>
                                <Grid size={{sm:6,xs:12}} container flexDirection="column" className="seekprof_borders">
                                    <span>Phone Number</span>
                                    <p>(123)456-7890</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Offcanvas.Body>
                </Offcanvas>
        </Box>
    );
};

export default DataTables;
